import { create } from 'zustand'
const store = (set: any) => ({
  prevPageNumber: 0,
  changePrevPageNumber: (newPage: number) =>
    set(() => ({ prevPageNumber: newPage })),
  globalLoader: false,
  setGlobalLoader: (newLoadingState: boolean) =>
    set(() => ({ globalLoader: newLoadingState })),
  modalBtnLoader: false,
  setModalBtnLoader: (state: boolean) => set(() => ({ modalBtnLoader: state })),
})
export const myStore = create(store)
