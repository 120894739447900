import { Row } from 'antd'
import { useDeleteAdult } from 'api/family/adult'
import AlertModal from 'helper/AlertModal'
import { useState } from 'react'
import { adultStore } from 'state/family/adult'
import { FamilyMemberAdult } from 'types/family/adult'
import deleteIcon from '../../../../../assets/Images/Trash.png'
import Edit from '../../../../../assets/Images/editblackPencil.svg'
import EmptyFamily from './EmptyFamily'
import Person from './Person'
import { infoMessage } from 'api/global'
import { guardianStore } from 'state/family/guardian'
import { FamilyMemberGuardian } from 'types/family/guardian'
import { useDeleteGuardian } from 'api/family/guardian'
import { useDeleteKid } from 'api/family/kid'
import { kidStore } from 'state/family/kid'
import { FamilyMemberKid } from 'types/family/kid'
const FamilyData = ({ setMembersVisible, fdata }: any) => {
  const [openDelete, setOpenDelete] = useState<boolean>(false)
  const [memberDetails, setMemberDetails] = useState({
    heading: '',
    message: '',
    id: undefined,
  })
  const [deleteMember, setDeletedMember] = useState<
    'adult' | 'guardian' | 'kid'
  >()
  const { setAdultData, setAdultCreationModal } = adultStore(store => ({
    setAdultData: store.setAdultData,
    setAdultCreationModal: store.setAdultCreationModal,
  }))
  const { setGuardianData, setGuardianCreationModal, setIsUpdaingGuardian } =
    guardianStore(store => ({
      setGuardianData: store.setGuardianData,
      setGuardianCreationModal: store.setGuardianCreationModal,
      setIsUpdaingGuardian: store.setIsUpdating,
    }))
  const { setKidData, setKidCreationModal } = kidStore(store => ({
    setKidData: store.setKidData,
    setKidCreationModal: store.setKidCreationModal,
  }))

  const { mutate: deleteAdultMutation, isLoading: deleteAdultMutationLoading } =
    useDeleteAdult()
  const { mutate: deleteGuardianMutation, isLoading: deleteGuardianLoading } =
    useDeleteGuardian()
  const { mutate: deleteKidMutation, isLoading: deleteKidMutationLoading } =
    useDeleteKid()
  const handleEdit = (
    data: FamilyMemberAdult | FamilyMemberGuardian | FamilyMemberKid,
    role: 'guardian' | 'adult' | 'kid',
  ) => {
    if (role === 'guardian') {
      setIsUpdaingGuardian(true)
      setGuardianData(data as FamilyMemberGuardian)
      setGuardianCreationModal(true)
      return
    }
    if (role === 'adult') {
      setAdultData(data as FamilyMemberAdult)
      setAdultCreationModal(true)
      return
    }
    if (role === 'kid') {
      setKidData(data as FamilyMemberKid)
      setKidCreationModal(true)
      return
    }
  }

  const handleDelete = (
    data: FamilyMemberAdult,
    member: 'adult' | 'guardian' | 'kid',
  ) => {
    setMemberDetails({
      heading: `Do you want to delete adult ${data.firstName}?`,
      message: `The ${data.firstName} will be deleted?`,
      id: data._id,
    })
    setDeletedMember(member)
    setOpenDelete(true)
  }

  const onYesClick = () => {
    switch (deleteMember) {
      case 'adult':
        deleteAdultMutation(memberDetails.id, {
          onSuccess: () => {
            setOpenDelete(false)
          },
          onError: () => {
            setOpenDelete(false)
          },
        })
        setDeletedMember(null)
        break
      case 'guardian':
        deleteGuardianMutation(memberDetails.id, {
          onSuccess: () => {
            setOpenDelete(false)
          },
          onError: () => {
            setOpenDelete(false)
          },
        })
        setDeletedMember(null)
        break
      case 'kid':
        deleteKidMutation(memberDetails.id, {
          onSuccess: () => {
            setOpenDelete(false)
          },
          onError: () => {
            setOpenDelete(false)
          },
        })
        setDeletedMember(null)
        break
      default:
        infoMessage('No member is selected')
    }
  }

  return (
    <>
      <div className="bg-white p-30 h-full h-100vh overflow-auto">
        {fdata?.guardians[0] || fdata?.kids[0] || fdata?.adults[0] ? (
          <div>
            <div className="flex justify-between align-center">
              <h4 className="text-20 w-600">Family Members</h4>
              <button
                className="borderd-btn"
                onClick={() => {
                  setMembersVisible(true)
                }}
              >
                Add members
              </button>
            </div>
            {fdata?.guardians[0] ? (
              <h5 className="text-14 w-600 mt-20 ">Guardians</h5>
            ) : (
              <></>
            )}
            {fdata?.guardians?.map((data: any) => {
              return (
                <>
                  <div className="flex justify-between align-center">
                    <Person
                      id={data?._id}
                      photo={data?.photo?.post}
                      name={data?.firstName + ' ' + data?.lastName}
                      relation={data?.relation?.name}
                      to="parent-profile"
                      bcase={'guardian'}
                    />
                    <Row>
                      <img
                        src={Edit}
                        alt="edit"
                        className="pointer pr-10px w-30px"
                        onClick={() => handleEdit(data, 'guardian')}
                      />
                      <img
                        className="pointer"
                        src={deleteIcon}
                        alt="deleteIcon"
                        style={{ marginLeft: '10px' }}
                        onClick={() => handleDelete(data, 'guardian')}
                      />
                    </Row>
                  </div>
                </>
              )
            })}
            {fdata?.kids[0] ? (
              <h5 className="text-14 w-600 mt-20 mb-20">Children</h5>
            ) : (
              <></>
            )}
            {fdata?.kids?.map((data: any) => {
              return (
                <>
                  <div className="flex justify-between align-center">
                    <Person
                      id={data?._id}
                      photo={data?.photo?.post}
                      name={data?.firstName + ' ' + data?.lastName}
                      relation={data?.relation?.name}
                      to="kid-profile"
                      bcase={'kid'}
                    />
                    <Row>
                      <img
                        src={Edit}
                        alt="edit"
                        className="pointer pr-10px w-30px"
                        onClick={() => handleEdit(data, 'kid')}
                      />
                      <img
                        className="pointer"
                        src={deleteIcon}
                        alt="deleteIcon"
                        style={{ marginLeft: '10px' }}
                        onClick={() => handleDelete(data, 'kid')}
                      />
                    </Row>
                  </div>
                </>
              )
            })}
            {fdata?.adults[0] ? (
              <h5 className="text-14 w-600 mt-20 mb-20">Authorized Adults</h5>
            ) : (
              <></>
            )}
            {fdata?.adults?.map((data: any) => {
              return (
                <>
                  <div className="flex justify-between align-center">
                    <Person
                      id={data?._id}
                      photo={data?.photo?.post}
                      name={data?.firstName + ' ' + data?.lastName}
                      relation={data?.relation?.name}
                      to="adult-profile"
                      bcase={'Adult'}
                    />
                    <Row>
                      <img
                        src={Edit}
                        alt="edit"
                        className="pointer pr-10px w-30px"
                        onClick={() => handleEdit(data, 'adult')}
                      />
                      <img
                        className="pointer"
                        src={deleteIcon}
                        alt="deleteIcon"
                        style={{ marginLeft: '10px' }}
                        onClick={() => handleDelete(data, 'adult')}
                      />
                    </Row>
                  </div>
                </>
              )
            })}
          </div>
        ) : (
          <EmptyFamily />
        )}
      </div>

      {openDelete && (
        <AlertModal
          isModalVisible={openDelete}
          setIsModalVisible={setOpenDelete}
          data={{
            name: 'Authorized adult',
            heading: memberDetails.heading,
          }}
          onYesClick={onYesClick}
          loading={
            deleteAdultMutationLoading ||
            deleteGuardianLoading ||
            deleteKidMutationLoading
          }
        />
      )}
    </>
  )
}

export default FamilyData
