import React, { useEffect, useState } from 'react'
import { Modal, TimePicker } from 'antd'
import closeIcon from 'assets/Images/close.svg'
import moment, { Moment } from 'moment'
import { CellClickEventArgs } from '@syncfusion/ej2-react-schedule'
import { creationEventStore } from 'state/eventCreationStore'
import SelectActivityModal from './SelectActivityModal'
import './styles/styles.scss'
import {
  calculateTimeDiffernce,
  convertMinutesToLocalTime,
  convertTimeToUtcMinutes,
  disabledHours,
  disabledMinutes,
  timeValidationCheck,
} from 'helper/utility'
import { infoMessage } from 'api/global'
import { EventStepTwoPayload, SpecialSchedulesTypes } from 'types/events'
import { convertDateToUtc } from '../../../utility'
import { useEventStepTwo } from 'api/EventCreation/CreationStep2'
import ButtonSpinner from 'components/global/ButtonSpinner'

interface EventArgs extends CellClickEventArgs {
  Id?: string
  eventActivityId?: string
  StartTime?: Date
  EndTime?: Date
}
interface ActivityTimeProps {
  openModal: boolean
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>
  defaultData: EventArgs
  specialSchedule?: SpecialSchedulesTypes
  operation:
    | 'view-activity'
    | 'delete-activity'
    | 'copy-activity'
    | 'edit-time'
    | 'overlapping'
    | 'change-activity'
}

const ActivityTime: React.FC<ActivityTimeProps> = ({
  openModal,
  setOpenModal,
  defaultData,
  specialSchedule,
  operation,
}) => {
  const { timings, _id } = creationEventStore(store => store.eventData)
  const setEventData = creationEventStore(store => store.setEventData)
  const activity = creationEventStore(store => store.activity)
  const setActivity = creationEventStore(store => store.setActivity)
  const { mutate: editTime, isLoading: eventStep2Loading } = useEventStepTwo()
  const handleCancel = () => {
    setOpenModal(false)
  }

  const [startTime, setStartTime] = useState<string>()
  const [endTime, setendTime] = useState<string>()
  const [defaultStartTime, setDefaultStartTime] = useState<Moment>(
    moment(defaultData?.startTime),
  )
  const [defaultEtartTime, setDefaultEndTime] = useState<Moment>(
    moment(defaultData?.endTime),
  )
  const [selectActivityModal, setSelectActivityModal] = useState<boolean>(false)
  const [eventHours, setEventHours] = useState({
    startHours: undefined,
    endHours: undefined,
    startMinutes: undefined,
    endMinutes: undefined,
  })

  const handleStartTimeChange = (time: Moment, timeString: string) => {
    const checkedTime = timeValidationCheck(
      time,
      eventHours.startHours,
      eventHours.endHours,
      eventHours.startMinutes,
      eventHours.endMinutes,
    )
    setDefaultStartTime(checkedTime)
    setStartTime(moment(checkedTime).format('hh:mm a'))
  }

  const handleEndTimeChange = (time: Moment, timeString: string) => {
    const checkedTime = timeValidationCheck(
      time,
      eventHours.startHours,
      eventHours.endHours,
      eventHours.startMinutes,
      eventHours.endMinutes,
    )
    setDefaultEndTime(checkedTime)
    setendTime(moment(checkedTime).format('hh:mm a'))
  }

  const getAdjustedTime = () => {
    let activityStartTime = new Date(defaultData.StartTime)
    let [time, modifier] = startTime.split(' ')
    let [hours, minutes] = time.split(':').map(Number)

    if (modifier === 'PM' && hours !== 12) {
      hours += 12
    }
    if (modifier === 'AM' && hours === 12) {
      hours = 0
    }

    activityStartTime.setHours(hours)
    activityStartTime.setMinutes(minutes)
    activityStartTime.setSeconds(0)

    return activityStartTime
  }

  const handleSubmit = () => {
    if (!startTime || !endTime) {
      return infoMessage('Start and end time is required')
    }
    if (calculateTimeDiffernce(startTime, endTime, 'minutes') < 15) {
      return infoMessage(
        'Difference between start and end-time atleast should be 15 minutes',
      )
    }
    if (defaultData.Id && operation !== 'overlapping') {
      const payload: EventStepTwoPayload = {
        isDefault: true,
        activitiesSchedule: {
          duration: calculateTimeDiffernce(startTime, endTime, 'minutes'),
          days: [convertDateToUtc(getAdjustedTime())],
          eventActivityId: defaultData.eventActivityId,
        },
      }

      editTime(
        { payload, eventId: _id, activityId: defaultData?.Id },
        {
          onSuccess: data => {
            setEventData(data)
            handleCancel()
          },
        },
      )
    } else if (defaultData.Id) {
      setActivity({
        ...activity,
        difference: calculateTimeDiffernce(startTime, endTime, 'minutes'),
        startTime: convertTimeToUtcMinutes(startTime),
        endTime: convertTimeToUtcMinutes(endTime),
        date: convertDateToUtc(getAdjustedTime()),
      })
      setSelectActivityModal(true)
      setOpenModal(false)
    } else {
      setActivity({
        ...activity,
        date: convertDateToUtc(defaultData.startTime),
        startTime: convertTimeToUtcMinutes(startTime),
        endTime: convertTimeToUtcMinutes(endTime),
        difference: calculateTimeDiffernce(startTime, endTime, 'minutes'),
      })
      setSelectActivityModal(true)
      setOpenModal(false)
    }
  }

  useEffect(() => {
    setDefaultStartTime(
      moment(defaultData?.startTime || defaultData?.StartTime),
    )
    setDefaultEndTime(moment(defaultData?.endTime || defaultData?.EndTime))
    setStartTime(
      moment(defaultData?.startTime || defaultData?.StartTime).format('h:mm a'),
    )
    setendTime(
      moment(defaultData?.endTime || defaultData?.EndTime).format('h:mm a'),
    )
  }, [defaultData])

  useEffect(() => {
    const startHour = parseInt(
      convertMinutesToLocalTime(
        specialSchedule ? specialSchedule.startTime : timings.startTime,
        'HH',
      ),
    )
    const endHour = parseInt(
      convertMinutesToLocalTime(
        specialSchedule ? specialSchedule.endTime : timings.endTime,
        'HH',
      ),
    )
    const startMinute = parseInt(
      convertMinutesToLocalTime(
        specialSchedule ? specialSchedule.startTime : timings.startTime,
        'mm',
      ),
    )
    const endMinute = parseInt(
      convertMinutesToLocalTime(
        specialSchedule ? specialSchedule.endTime : timings.endTime,
        'mm',
      ),
    )

    setEventHours({
      ...eventHours,
      startHours: startHour,
      endHours: endHour,
      startMinutes: startMinute,
      endMinutes: endMinute,
    })
  }, [specialSchedule])

  return (
    <>
      <div>
        <Modal
          open={openModal}
          onCancel={handleCancel}
          className="checkout-model"
        >
          <div className="flex-center pt-20px justify-between ">
            <h2 className="w-600 text-18">Select activity time</h2>
            <img
              src={closeIcon}
              alt="close-button"
              onClick={handleCancel}
              className="close button pointer"
            />
          </div>
          <div className="mt-30px text-center activity-time">
            <div className={`time-picker flex mt-30`}>
              <div className="start-time" style={{ width: '50%' }}>
                <h3 className="text-start w-500 text-14">Start Time</h3>
                <TimePicker
                  format="h:mm a"
                  onChange={handleStartTimeChange}
                  className="mt-10 w-100 form-control-field"
                  value={defaultStartTime}
                  use12Hours={true}
                  disabledHours={() =>
                    disabledHours(
                      eventHours.startHours,
                      eventHours.endHours + 1,
                    )
                  }
                  disabledMinutes={selectedHour =>
                    disabledMinutes(
                      eventHours.startHours,
                      eventHours.endHours,
                      eventHours.startMinutes,
                      eventHours.endMinutes,
                      selectedHour,
                    )
                  }
                />
              </div>
              <div className="end-time ml-20" style={{ width: '50%' }}>
                <h3 className="text-start w-500 text-14">End Time</h3>
                <TimePicker
                  format="h:mm a"
                  onChange={handleEndTimeChange}
                  className="mt-10 w-100 form-control-field"
                  value={defaultEtartTime}
                  disabledHours={() =>
                    disabledHours(
                      eventHours.startHours,
                      eventHours.endHours + 1,
                    )
                  }
                  disabledMinutes={selectedHour =>
                    disabledMinutes(
                      eventHours.startHours,
                      eventHours.endHours,
                      eventHours.startMinutes,
                      eventHours.endMinutes,
                      selectedHour,
                    )
                  }
                />
              </div>
            </div>
          </div>

          <div className="mt-30px flex gap-20px justify-center">
            <button
              className="form-btn-secondary  pointer "
              onClick={handleCancel}
            >
              Cancel
            </button>
            <button className="form-btn pointer" onClick={handleSubmit}>
              {eventStep2Loading ? (
                <ButtonSpinner size={20} color="#000" />
              ) : (
                'Save'
              )}
            </button>
          </div>
        </Modal>
      </div>
      <SelectActivityModal
        modalOpen={selectActivityModal}
        setmodalopen={setSelectActivityModal}
      />
    </>
  )
}

export default ActivityTime
