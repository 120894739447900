import { Select } from 'antd'
import DocumentTable from 'components/global/Tables/DocumentTable'
import { useParams } from 'react-router-dom'
import DocumentTableHeader from 'components/global/headers/DocumentTable'

const { Option } = Select
const GuardianDocuments = () => {
  const { id }: { id: string } = useParams()

  return (
    <div className="bg-grey container padding-top-10">
      <div className="profile_container">
        <DocumentTableHeader />
        <DocumentTable memberId={id} />
      </div>
    </div>
  )
}
export default GuardianDocuments
