import { Col, Form, FormInstance, Input, Row, Slider } from 'antd'
import React, { useEffect, useState } from 'react'
import { FamilyMemberKid, KidAbilities } from 'types/family/kid'
import sadEmoji from 'assets/Images/sad.svg'
import happyEmoji from 'assets/Images/happy.svg'
import smileEmoji from 'assets/Images/smile.svg'
import { myStore } from 'state/store'
import { kidStore } from 'state/family/kid'
import '../styles.scss'
import { useUpdateKid } from 'api/family/kid'
import moment from 'moment'

interface AbilitiesProps {
  form: FormInstance
}

interface KidStoreStates {
  kidData: FamilyMemberKid
  setKidData: (state: FamilyMemberKid) => void
  setKidCounter: (state: number) => void
  setKidCreationModal: (state: boolean) => void
}

const Abilities: React.FC<AbilitiesProps> = ({ form }) => {
  const {
    kidData,
    setKidData,
    setKidCounter,
    setKidCreationModal,
  }: KidStoreStates = kidStore(store => ({
    kidData: store.kidData,
    setKidData: store.setKidData,
    setKidCounter: store.setKidCounter,
    setKidCreationModal: store.setKidCreationModal,
  }))
  const setModalBtnLoader = myStore(store => store.setModalBtnLoader)
  const [readingLevel, setReadingLevel] = useState<
    'bad' | 'average' | 'good' | 'very-good' | 'excellent'
  >(kidData?.abilities?.readingLevel || undefined)
  const [kidMood, setKidMood] = useState<'sad' | 'natural' | 'happy'>(
    kidData?.abilities?.kidMood || undefined,
  )

  const { mutate: updateKidMutation, isLoading: updateKidLoading } =
    useUpdateKid()

  const marks = {
    0: 'Bad',
    25: 'Average',
    50: 'Good',
    75: 'Very Good',
    100: 'Excellent',
  }

  const mood = {
    0: <img alt="" height={30} width={30} src={sadEmoji} />,
    50: <img alt="" height={30} width={30} src={happyEmoji} />,
    100: <img alt="" height={30} width={30} src={smileEmoji} />,
  }

  const setReadingNumberByString = (readingLevel: string) => {
    if (readingLevel === 'bad') {
      return 0
    } else if (readingLevel === 'average') {
      return 25
    } else if (readingLevel === 'good') {
      return 50
    } else if (readingLevel === 'very-good') {
      return 75
    } else if (readingLevel === 'excellent') {
      return 100
    }
  }

  const setKidMoodByString = (kidMood: string) => {
    switch (kidMood) {
      case 'sad':
        return 0
      case 'natural':
        return 50
      case 'happy':
        return 100
    }
  }

  const handleReadingLevel = (value: number) => {
    if (value === 0) {
      setReadingLevel('bad')
    } else if (value === 25) {
      setReadingLevel('average')
    } else if (value === 50) {
      setReadingLevel('good')
    } else if (value === 75) {
      setReadingLevel('very-good')
    } else if (value === 100) {
      setReadingLevel('excellent')
    }
  }

  const handleKidMood = (value: number) => {
    if (value === 0) {
      setKidMood('sad')
    } else if (value === 50) {
      setKidMood('natural')
    } else if (value === 100) {
      setKidMood('happy')
    }
  }

  const formSchema = {
    strength:
      kidData && kidData.abilities ? kidData.abilities.strength : undefined,
    areaOfImprovements:
      kidData && kidData.abilities
        ? kidData.abilities.areaOfImprovements
        : undefined,
    howToComfort:
      kidData && kidData.abilities ? kidData.abilities.howToComfort : undefined,
    readingLevel:
      kidData && kidData.abilities
        ? setReadingNumberByString(kidData?.abilities?.readingLevel)
        : undefined,
    kidMood:
      kidData && kidData.abilities
        ? setKidMoodByString(kidData?.abilities?.kidMood)
        : undefined,
  }

  const onFinish = (values: KidAbilities) => {
    const payload = {
      ...kidData,
      abilities: {
        ...values,
        readingLevel,
        kidMood,
      },
    }
    payload.dob = kidData.dob
      ? moment(kidData.dob).format('YYYY-MM-DD')
      : undefined
    updateKidMutation(
      { kidId: kidData._id, payload },
      {
        onSuccess: () => {
          setKidData(null)
          setKidCounter(0)
          setKidCreationModal(false)
        },
      },
    )
  }

  // updating the states after adding
  useEffect(() => {
    setReadingLevel(readingLevel)
    setKidMood(kidMood)
  }, [handleReadingLevel, handleKidMood])

  useEffect(() => {
    setModalBtnLoader(updateKidLoading)
  }, [updateKidLoading])

  return (
    <div className="mt-20px">
      <Form
        name="kid-creation-abilities"
        initialValues={formSchema}
        form={form}
        onFinish={onFinish}
        layout="vertical"
        autoComplete="off"
      >
        <Row>
          <Col span={24} className="mt-10px">
            <label className="input-label">Lists of strengths</label>
            <Form.Item name="strength">
              <Input placeholder="Lists of strengths" name="strength" />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24} className="mt-10px">
            <label className="input-label">Lists of areas of improvement</label>
            <Form.Item name="areaOfImprovements">
              <Input
                placeholder="Lists of strengths"
                name="areaOfImprovements"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24} className="mt-10px">
            <label className="input-label">
              How to comfort if hurt, scared, worried
            </label>
            <Form.Item name="howToComfort">
              <Input placeholder="Lists of strengths" name="howToComfort" />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={8} className="mt-10px">
            <label className="input-label">Reading Level</label>
          </Col>
          <Col span={14}>
            <Form.Item name="readingLevel">
              <Slider
                className="pb-10 reading"
                marks={marks}
                included={false}
                step={25}
                onChange={(value: any) => {
                  handleReadingLevel(value)
                }}
                handleStyle={{
                  width: '24px',
                  height: '24px',
                  borderRadius: '50%',
                  top: '0',
                  borderWidth: '5px',
                  borderColor: '#2ba7ca',
                }}
                trackStyle={{ backgroundColor: '#ebedf1' }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={12} className="mt-10px">
            <label className="input-label">
              Child's mood on an average day
            </label>
          </Col>
          <Col span={10}>
            <Form.Item name="kidMood">
              <Slider
                className="mood emoji-items"
                marks={mood}
                step={50}
                dots={true}
                included={false}
                onChange={(v: any) => {
                  handleKidMood(v)
                }}
                handleStyle={{
                  width: '24px',
                  height: '24px',
                  borderRadius: '50%',
                  top: '0',
                  borderWidth: '5px',
                  borderColor: '#2ba7ca',
                }}
                trackStyle={{ backgroundColor: '#ebedf1' }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default Abilities
