import React from 'react'
import * as phosphorIcon from '@phosphor-icons/react'
import { capitalizeFirstLetter } from '../../helper/utility'

interface PropsType {
  nameIcon: string
  info?: string
  color?: string
  size?: number
  heading: string
  text: string
}

const IconAndContent: React.FC<PropsType> = ({
  nameIcon,
  color,
  size,
  heading,
  text,
}) => {
  const IconComponent = phosphorIcon[nameIcon]
  if (!IconComponent) {
    return <>Icon not exist</>
  }

  return (
    <div className="flex align-center gap-16px">
      <div className="flex align-center justify-center radius-rounded px-15px py-15px bg-greyLight">
        <IconComponent
          size={size || 20}
          color={color || '#000'}
          className="pt-2"
        />
      </div>
      <div>
        <h3 className="text-12 w-600 text-gray">{heading}</h3>
        <p className="text-16 w-400">{text}</p>
      </div>
    </div>
  )
}

export default IconAndContent
