import React, { useEffect, useState } from 'react'
import { Col, Form, FormInstance, Input, Row } from 'antd'
import checker from 'assets/Images/checker.svg'
import active_checker from 'assets/Images/active_checker.svg'
import { guardianStore } from 'state/family/guardian'
import {
  CreateGuardianPayload,
  FamilyMemberGuardian,
} from 'types/family/guardian'
import { useCreateGuardian } from 'api/family/guardian'
import { myStore } from 'state/store'

interface CreatePasswordProps {
  form: FormInstance
  familyId: string
}

interface GuardianStoreState {
  guardianData: FamilyMemberGuardian
  setGuardianData: (state: FamilyMemberGuardian) => void
  guardianCounter: number
  setGuardianCounter: (state: number) => void
  setGuardianExistModal: (state: boolean) => void
  setGuardianCreationModal: (state: boolean) => void
}

const CreatePassword: React.FC<CreatePasswordProps> = ({ form, familyId }) => {
  const [passwordConditions, setPasswordConditions] = useState({
    length: false,
    containsNumbers: false,
    containsUppercase: false,
  })
  const { mutate: createGuardianMutation, isLoading: createGuardianLoading } =
    useCreateGuardian()
  const {
    guardianData,
    setGuardianData,
    setGuardianCounter,
    setGuardianExistModal,
    setGuardianCreationModal,
  }: GuardianStoreState = guardianStore(store => ({
    guardianData: store.guardianData,
    setGuardianData: store.setGuardianData,
    guardianCounter: store.guardianCounter,
    setGuardianCounter: store.setGuardianCounter,
    setGuardianExistModal: store.setGuardianExistAlready,
    setGuardianCreationModal: store.setGuardianCreationModal,
  }))
  const setModalBtnLoader = myStore(store => store.setModalBtnLoader)

  const handlePasswordChange = (password: string) => {
    setPasswordConditions({
      length: password.length >= 8,
      containsNumbers: /\d/.test(password),
      containsUppercase: /[A-Z]/.test(password),
    })
  }

  const validatePassword = (_: any, value: string) => {
    if (!value) {
      return Promise.reject(new Error('Password is required'))
    }
    if (value.length < 8) {
      return Promise.reject(
        new Error('Password must be at least 8 characters long'),
      )
    }
    if (!/\d/.test(value)) {
      return Promise.reject(
        new Error('Password must contain at least one number'),
      )
    }
    if (!/[A-Z]/.test(value)) {
      return Promise.reject(
        new Error('Password must contain at least one capital letter'),
      )
    }
    return Promise.resolve()
  }

  const confirmPassword = ({ getFieldValue }: any) => ({
    validator(_: any, value: string) {
      if (!value || getFieldValue('password') === value) {
        return Promise.resolve()
      }
      return Promise.reject(new Error('Passwords do not match'))
    },
  })

  const onFinish = (values: { password: string; confirmPassword: string }) => {
    const payload: CreateGuardianPayload = {
      ...guardianData,
      password: values.password,
    }
    createGuardianMutation(
      { familyId, payload },
      {
        onSuccess: () => {
          setGuardianData(null)
          setGuardianCounter(0)
          setGuardianExistModal(false)
          setGuardianCreationModal(false)
        },
      },
    )
  }

  useEffect(() => {
    setModalBtnLoader(createGuardianLoading)
  }, [createGuardianLoading])

  return (
    <Form
      name="create-password-form"
      form={form}
      onFinish={onFinish}
      layout="vertical"
      autoComplete="off"
      onValuesChange={changedValues => {
        if (changedValues.password) {
          handlePasswordChange(changedValues.password)
        }
      }}
    >
      <p className="text-14 w-400 mt-20px">Please create a secure password.</p>
      <Row gutter={24} className="mb-40 mt-15px">
        <Col span={24}>
          <Form.Item
            name="password"
            className="password-field"
            rules={[{ validator: validatePassword }]}
            hasFeedback
          >
            <Input.Password placeholder="Enter new password" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="confirmPassword"
            className="password-field"
            dependencies={['password']}
            rules={[confirmPassword(form)]}
            hasFeedback
          >
            <Input.Password placeholder="Re-enter new password" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <div className="w-100 d-flex gap-20">
            <div>
              {passwordConditions.length ? (
                <img alt="valid" src={active_checker} className="mr-10" />
              ) : (
                <img alt="invalid" src={checker} className="mr-10" />
              )}
              8 characters
            </div>
            <div>
              {passwordConditions.containsNumbers ? (
                <img alt="valid" src={active_checker} className="mr-10" />
              ) : (
                <img alt="invalid" src={checker} className="mr-10" />
              )}
              1 number
            </div>
            <div>
              {passwordConditions.containsUppercase ? (
                <img alt="valid" src={active_checker} className="mr-10" />
              ) : (
                <img alt="invalid" src={checker} className="mr-10" />
              )}
              1 capital letter
            </div>
          </div>
        </Col>
      </Row>
    </Form>
  )
}

export default CreatePassword
