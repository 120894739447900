import { Steps } from 'antd'
import React from 'react'
import { guardianStore } from 'state/family/guardian'
const { Step } = Steps
const GuardiansStep: React.FC = () => {
  const { guardianCounter } = guardianStore(store => ({
    guardianCounter: store.guardianCounter,
  }))
  const steps = [
    {
      title: 'Personal Details',
    },
    {
      title: 'Add password',
    },
  ]
  return (
    <div className="border-btm pb-15px">
      <Steps current={guardianCounter}>
        {steps.map(item => (
          <Step key={item.title} className="step-styling" title={item.title} />
        ))}
      </Steps>
    </div>
  )
}

export default GuardiansStep
