import React, { useEffect, useState } from 'react'
import { Modal } from 'antd'
import closeIcon from 'assets/Images/close.svg'
import { useGetActivityById } from 'api/EventCreation/CreationStep2'
import Spinner from 'components/Spinner'
import { CategoryType } from 'types/globalTypes'
import { List } from '@phosphor-icons/react'
import AddActivity from './addActivity/AddActivity'
import { creationEventStore } from 'state/eventCreationStore'

interface ViewActivityProps {
  modalOpen: boolean
  modalClose: React.Dispatch<React.SetStateAction<boolean>>
  type: 'default' | 'specific'
  activityId: string
}

const ViewActivity: React.FC<ViewActivityProps> = ({
  modalOpen,
  modalClose,
  type,
  activityId,
}) => {
  const [activityModal, setActivityModal] = useState<boolean>(false)
  const setCustomActivity = creationEventStore(store => store.setCustomActivity)
  const { data, isLoading, isFetching, refetch } =
    useGetActivityById(activityId)

  const handleCancel = () => {
    modalClose(false)
  }

  const hanldeEditActivity = () => {
    setActivityModal(true)
    setCustomActivity(data)
  }

  useEffect(() => {
    refetch()
  }, [activityId])
  return (
    <>
      <div>
        <Modal
          open={modalOpen}
          onCancel={handleCancel}
          className="view-activity-modal"
          footer={false}
        >
          <div className="flex-center pt-20px justify-between">
            <h2 className="text-24 w-600">
              {isLoading || isFetching ? null : data.name}
            </h2>
            <img
              src={closeIcon}
              alt="close-button"
              onClick={handleCancel}
              className="close button pointer"
            />
          </div>

          <div
            className={`activity-view-cont scroller overflow-auto-y ${
              type === 'default' ? 'h-30rem' : 'h-27rem'
            }`}
          >
            {isLoading || isFetching ? (
              <div className="h-full w-full flex align-center justify-center">
                <Spinner />
              </div>
            ) : (
              <div className="mt-20px pr-10px">
                {data.categoryIds?.length > 0 && (
                  <Fields heading={'category'} text={data.categories} />
                )}
                {data?.energyLevelId && (
                  <Fields heading="Energy level" text={data.energyLevel} />
                )}
                {data.highlightedElementId && (
                  <Fields
                    heading="Highlighted Elements"
                    text={data.highlightedElement}
                  />
                )}
                {data?.goalId && (
                  <Fields heading="Overall Goals" text={data.goal} />
                )}
                {data?.time && (
                  <Fields heading="Time Needed" text={data.time} />
                )}
                {data?.gradeIds?.length > 0 && (
                  <Fields heading="Grade" text={data.grades} />
                )}
                {data?.environment && (
                  <Fields
                    heading="Preferred Environment"
                    text={data.environment}
                  />
                )}
                {data?.noOfKids && (
                  <Fields heading="Number of Kids" text={data.noOfKids} />
                )}
                {data?.mentors && (
                  <Fields
                    heading="People Needed"
                    text={`${data.mentors} Mentors, ${data.interns} Interns`}
                  />
                )}
                {data?.plan && (
                  <Fields
                    heading="Activity plan"
                    text={data.plan}
                    type={'desc'}
                  />
                )}
                {data.tasks?.length > 0 && (
                  <Fields heading="Supplies" text={data.tasks} type={'desc'} />
                )}
                {data.galleryIds?.length > 0 &&
                  data.gallery.map(item => {
                    return (
                      <div
                        key={item._id}
                        className="flex align-center justify-center w-80p mx-auto mt-20px radius-12"
                      >
                        <img
                          src={item.post}
                          alt={item._id}
                          className="w-full radius-12"
                        />
                      </div>
                    )
                  })}
              </div>
            )}
          </div>

          {type === 'specific' && (
            <div className="mt-10px flex align-center justify-between">
              <button className="create-activity-btn" onClick={handleCancel}>
                Cancel
              </button>
              <button className="confirm-btn" onClick={hanldeEditActivity}>
                Edit
              </button>
            </div>
          )}
        </Modal>
      </div>
      {activityModal && (
        <AddActivity
          modalOpen={activityModal}
          setModalOpen={setActivityModal}
        />
      )}
    </>
  )
}

const Fields = ({
  heading,
  text,
  type,
}: {
  heading: string
  text: string | number | CategoryType[] | CategoryType | string[]
  type?: string
}) => {
  return (
    <div
      className={`flex justify-between align-start mt-15px ${
        type === 'desc' && 'flex-column'
      }`}
    >
      <h3 className="text-14 w-600">{heading}</h3>
      {typeof text === 'string' || typeof text === 'number' ? (
        <p className="text-14 w-400">{text}</p>
      ) : Array.isArray(text) ? (
        <div>
          {text.map((item: CategoryType | string) => {
            return typeof item === 'string' ? (
              <div key={item} className="flex align-center gap-16px mt-5px">
                <List size={20} color={'#000'} />
                <p>{item}</p>
              </div>
            ) : (
              <div
                key={item._id}
                className="flex align-center gap-10px mt-10px justify-end"
              >
                <p>{item.name}</p>
                {item?.icon && (
                  <img src={item?.icon} alt={item._id} width={26} height={26} />
                )}
              </div>
            )
          })}
        </div>
      ) : (
        <div className="flex align-center gap-10px justify-end">
          <p>{text?.name}</p>
          {text?.icon && (
            <img src={text?.icon} alt={text._id} width={26} height={26} />
          )}
        </div>
      )}
    </div>
  )
}

export default ViewActivity
