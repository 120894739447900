import React from 'react'
interface NoDataFoundProps {
  message?: string
}
const NoDataFound: React.FC<NoDataFoundProps> = ({ message }) => {
  return (
    <div className="flex justify-center align-center py-40px">
      <h3 className="text-14 b-500">{message || 'No Data found'}</h3>
    </div>
  )
}

export default NoDataFound
