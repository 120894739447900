import React, { useEffect, useState } from 'react'
import { Empty, Pagination } from 'antd'
import { useGetMedia } from 'api/family/kid'
import Spinner from 'components/Spinner'
import usePagination from 'helper/usePagination'
import { useParams } from 'react-router-dom'
import {
  allowedImageTypes,
  allowedAudioTypes,
  allowedVideoTypes,
} from 'helper/constants'
import { Photo, File } from 'types/globalTypes'
import Media from 'components/global/Media'

const KidMedia = () => {
  const { id }: { id: string } = useParams()
  const [parms, setParms] = useState({
    page: 1,
    limit: 20,
  })
  const { isLoading, data, refetch } = useGetMedia(id, parms)
  // const [paginationInfo, onTableChange, PaginationNoTabel] = usePagination({
  //   totalCount: data?.count || 0,
  //   currentPage: parms.page,
  //   setParams: setParms,
  // })

  useEffect(() => {
    refetch()
  }, [id])
  return (
    <div className="bg-grey container padding-top-10">
      <div className="profile_container">
        {isLoading ? (
          <Spinner />
        ) : (
          // <div>
          //   {data.count > 0 ? (
          //     <div>
          //       <div className="flex gap-40px flex-wrap">
          //         {data.records.map((media: File | Photo) => {
          //           return allowedImageTypes.includes(media.ext) ? (
          //             <div key={media?._id}>
          //               <img
          //                 src={(media as Photo).post}
          //                 width={300}
          //                 height={300}
          //                 alt={(media as Photo).thumb}
          //               />
          //             </div>
          //           ) : allowedVideoTypes.includes(media.ext) ? (
          //             <div key={media?._id}>
          //               <video width="300" height="300" controls>
          //                 <source src={(media as File).link} type="video/mp4" />
          //               </video>
          //             </div>
          //           ) : allowedAudioTypes.includes(media.ext) ? (
          //             <div key={media?._id}>
          //               <audio controls>
          //                 <source
          //                   src={(media as File).link}
          //                   type="audio/mpeg"
          //                 />
          //                 Your browser does not support the audio element.
          //               </audio>
          //             </div>
          //           ) : null
          //         })}
          //       </div>
          //       {(data.hasNextPage || data.hasPrevPage) && (
          //         <div className="mt-40px">
          //           <Pagination
          //             className="pb-20"
          //             style={{ width: '100%', display: 'flex' }}
          //             {...paginationInfo}
          //             onChange={PaginationNoTabel}
          //           />
          //         </div>
          //       )}
          //     </div>
          //   ) : (
          //     <div className="flex align-center justify-center h-50vh">
          //       <Empty />
          //     </div>
          //   )}
          // </div>
          <Media data={data} parms={parms} setParms={setParms} />
        )}
      </div>
    </div>
  )
}

export default KidMedia
