import React from 'react'
import CustomModal from 'components/global/CustomModal'
import useFormSubmit from 'helper/Hooks/formSubmit'
import { guardianStore } from 'state/family/guardian'
import CustomModalFooter from 'components/global/CustomModalFooter'
import FindEmail from '../AuthorizedAdult/FindEmail'
import FindEmailForGuardian from './ModalContent/FindEmail'

interface GuardianModalProps {
  familyId: string
}

const GuardianModal: React.FC<GuardianModalProps> = ({ familyId }) => {
  const { form, handleSubmit } = useFormSubmit()
  const { guardianModalVisible, setGuardianModalVisible } = guardianStore(
    store => ({
      guardianModalVisible: store.guardianModalVisible,
      setGuardianModalVisible: store.setGuardianModalVisible,
    }),
  )

  const handleClose = () => {
    setGuardianModalVisible(false)
  }

  return (
    <>
      <CustomModal
        openModal={guardianModalVisible}
        closeModal={setGuardianModalVisible}
        title="Add Guardians"
        footer={
          <CustomModalFooter
            multibutton={false}
            btnAlignment="justify-end"
            btnText="Find account"
            btnType="primary"
            closeModal={handleClose}
            onSubmit={handleSubmit}
          />
        }
      >
        <FindEmailForGuardian form={form} />
      </CustomModal>
    </>
  )
}

export default GuardianModal
