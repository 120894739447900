import {
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  TimePicker,
} from 'antd'
import React, { useEffect, useState } from 'react'
import closeIcon from 'assets/Images/close.svg'
import { useCreateEmailTemplate } from 'api/EmailTemplate'
import { EmailTemplate, EmailTemplateResp } from 'types/globalTypes'
import { creationEventStore } from 'state/eventCreationStore'
import { EmailTemplateCreation } from 'types/eventStep5'
import ButtonSpinner from 'components/global/ButtonSpinner'
import { formValidated } from 'helper/utility'
import moment from 'moment'
import ConditionsToSend from './ConditionsToSend'
import CustomTime from './CustomTime'
import { useEditEmailTemplateFromEvent } from 'api/EventCreation/creationStep5'
interface TemplateDetailsProps {
  openModal: boolean
  closeModal: React.Dispatch<React.SetStateAction<boolean>>
  body: string
  closeParent: () => void
  data?: EmailTemplateResp | undefined
}

const TemplateDetails: React.FC<TemplateDetailsProps> = ({
  openModal,
  closeModal,
  body,
  closeParent,
  data,
}) => {
  const [form] = Form.useForm()
  const { organizationId, _id } = creationEventStore(store => store.eventData)
  const setEventData = creationEventStore(store => store.setEventData)
  const { mutate: createTemplatemutate, isLoading: createTemplateLoading } =
    useCreateEmailTemplate()
  const { mutate: updateEvent, isLoading: updateEventTemplateLoading } =
    useEditEmailTemplateFromEvent()
  const [isCustomTime, setIsCustomTime] = useState<boolean>(false)

  const formSchema = {
    templateName: data ? data.templateName : undefined,
    timeQuantity: data ? data.conditionsToSend?.timeQuantity : undefined,
    timePeriod: data ? data.conditionsToSend?.timePeriod : undefined,
    timeFrame: data ? data.conditionsToSend?.timeFrame : undefined,
    conditionType: data ? data.conditionsToSend?.conditionType : undefined,
    date: data ? moment(data.customTimeToSend) : undefined,
    time: data ? moment(data.customTimeToSend) : undefined,
  }
  const onFinish = (values: any) => {
    if (!formValidated(values)) return
    const payload: EmailTemplate = {
      body,
      type: 'event',
      templateName: values.templateName,
    }
    if (isCustomTime) {
      payload.customTimeToSend = moment
        .utc(
          `${moment(values.date).format('YYYY-MM-DD')} ${moment
            .utc(values.time)
            .format('HH:mm')}`,
        )
        .format()
    } else {
      payload.conditionsToSend = {
        timeQuantity: values.timeQuantity,
        timePeriod: values.timePeriod,
        timeFrame: values.timeFrame,
        conditionType: values.conditionType,
      }
    }
    if (data) {
      updateEvent(
        { payload, eventId: _id, templateId: data._id },
        {
          onSuccess: () => {
            closeParent()
          },
        },
      )
    } else {
      createTemplatemutate(
        { payload, orgId: organizationId },
        {
          onSuccess: () => {
            closeParent()
          },
        },
      )
    }
  }
  const handleCancel = () => {
    closeModal(false)
  }

  const hanldeChange = (value: string) => {
    if (value === 'customTimeToSend') {
      setIsCustomTime(true)
    } else {
      setIsCustomTime(false)
    }
  }

  return (
    <div>
      <Modal
        open={openModal}
        onCancel={handleCancel}
        className="confirmation-email"
        footer={false}
        width={700}
        centered
      >
        <div className="flex-center pt-20px justify-between ">
          <h2 className="w-600 text-18">confirmation email template</h2>
          <img
            src={closeIcon}
            alt="close-button"
            onClick={handleCancel}
            className="close button pointer"
          />
        </div>
        <div className="mt-20px h-25rem overflow-auto-y overflow-hidden-x scroller">
          <Form
            form={form}
            layout="vertical"
            initialValues={formSchema}
            onFinish={onFinish}
          >
            <Row gutter={10}>
              <Col>
                <label className="input-label required-input">
                  Template name
                </label>
                <Form.Item className="form-control" name="templateName">
                  <Input
                    className="custom-input-field"
                    placeholder="Template name"
                  />
                </Form.Item>
              </Col>
            </Row>

            <div>
              <label className="input-label">Condition to send</label>
              {!isCustomTime ? (
                <ConditionsToSend setIsCustomTime={setIsCustomTime} />
              ) : (
                <CustomTime setIsCustomTime={setIsCustomTime} />
              )}
            </div>

            <div
              className="px-20px py-15px radius-12 mt-20px"
              style={{ background: '#FFF9E1' }}
            >
              <p className="text-14 w-400">
                Want to trigger an action immediately after something else
                occurs? Set its timing to 0 minutes, days, etc.{' '}
              </p>
            </div>

            <Row
              className="justify-end gap-16px"
              style={{ marginTop: '8.4rem' }}
            >
              <button
                className="confirm-btn"
                type="submit"
                disabled={createTemplateLoading || updateEventTemplateLoading}
              >
                {createTemplateLoading || updateEventTemplateLoading ? (
                  <ButtonSpinner size={20} color="#000" />
                ) : (
                  'Confirm and save'
                )}
              </button>
            </Row>
          </Form>
        </div>
      </Modal>
    </div>
  )
}

export default TemplateDetails
