import { Form } from 'antd'

const useFormSubmit = () => {
  const [form] = Form.useForm()

  const handleSubmit = () => {
    form.submit()
  }

  return { form, handleSubmit }
}

export default useFormSubmit
