import { Steps } from 'antd'
import React from 'react'
import { adultStore } from 'state/family/adult'
const { Step } = Steps
const AdultSteps: React.FC = () => {
  const { adultCurrentStep } = adultStore(store => ({
    adultCurrentStep: store.adultCurrentStep,
  }))
  const steps = [
    {
      title: 'Personal Details',
    },
    {
      title: 'Select child',
    },
  ]
  return (
    <div className="border-btm pb-15px">
      <Steps current={adultCurrentStep}>
        {steps.map(item => (
          <Step key={item.title} className="step-styling" title={item.title} />
        ))}
      </Steps>
    </div>
  )
}

export default AdultSteps
