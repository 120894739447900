import React, { useState } from 'react'
import './styles/additional-settings.scss'
import { CaretLeft } from '@phosphor-icons/react'
import { Button } from 'antd'
import { creationEventStore } from 'state/eventCreationStore'
import CoverAndDescription from './essentialComp/CoverAndDescription'
import AdditionalSettings from './essentialComp/AdditionalSettings'
import { useEventAdditionalSetting } from 'api/EventCreation/creationStep5'
import ButtonSpinner from 'components/global/ButtonSpinner'
import { RequiredDeatilsTypes } from 'types/eventStep5'
import Confirmationmodal from './Modals/Confirmationmodal'

interface AdditionalEventSettingsProps {
  handleNext: (data: any) => void
  handleBack: () => void
}

const AdditionalEventSettings: React.FC<AdditionalEventSettingsProps> = ({
  handleBack,
}) => {
  const eventData = creationEventStore(store => store.eventData)
  const payload = creationEventStore(store => store.step5Payload)
  const setPayload = creationEventStore(store => store.setStep5Payload)
  const [confirmModal, setConfirmModal] = useState<boolean>(false)

  const { isLoading: step5Loading } = useEventAdditionalSetting()

  const mapToKeyValueObject = (arr: RequiredDeatilsTypes[]) => {
    return arr?.reduce((acc, item) => {
      acc[item.key] = item.value
      return acc
    }, {})
  }

  const handlePublish = () => {
    const mergedPayload = {
      ...payload,
      parentRequiredDetails: mapToKeyValueObject(
        eventData.requiredDetails?.guardians,
      ),
      authorizedAdultRequiredDetails: mapToKeyValueObject(
        eventData.requiredDetails?.adults,
      ),
      kidRequiredDetails: mapToKeyValueObject(eventData.requiredDetails?.kids),
    }

    setPayload(mergedPayload)

    setConfirmModal(true)
  }

  return (
    <>
      <div className="additional-setting-container">
        <div>
          <h3 className="text-24 w-600"> Step 5. Additional Events settings</h3>
        </div>
        <div className="mt-30px overflow-auto-y scroller overflow-hidden-x content-container">
          <div className="flex gap-40px justify-between">
            <div className="inner-cont">
              <CoverAndDescription />
            </div>
            <div className="inner-cont">
              <AdditionalSettings />
            </div>
          </div>
        </div>

        <div className="mt-15px event-button-cont">
          <h5
            className="flex gap-10px align-center pointer"
            onClick={() => handleBack()}
          >
            <CaretLeft size={20} color="#000" />
            <span className="text-15 w-500">Back</span>
          </h5>
          <Button className="stepper-button" onClick={handlePublish}>
            {step5Loading ? (
              <ButtonSpinner size={20} color="#000" />
            ) : (
              'Publish event'
            )}
          </Button>
        </div>
      </div>

      {confirmModal && (
        <Confirmationmodal
          openModal={confirmModal}
          closeModal={setConfirmModal}
        />
      )}
    </>
  )
}

export default AdditionalEventSettings
