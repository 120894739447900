import { kidStore } from 'state/family/kid'
import KidAuthorizedAdults from './KidAuthorizedAdults'
import KidGeneralDetails from './KidGeneralDetails'
import KidCreation from '../../FamilyProfile/FamilyMembers/Modals/Kid/KidCreation'
import { FamilyMemberKid } from 'types/family/kid'

const KidBasicInfo = ({ data }: { data: FamilyMemberKid }) => {
  const { kidCreationModal } = kidStore(store => ({
    kidCreationModal: store.kidCreationModal,
  }))

  return (
    <>
      <div className="bg-grey  padding-top-10 ">
        <div className="grid grid-50 ">
          <KidGeneralDetails GeneralData={data} />
          <KidAuthorizedAdults />
        </div>
      </div>

      {kidCreationModal && <KidCreation familyId={data.familyId} />}
    </>
  )
}
export default KidBasicInfo
