import React, { useEffect, useState } from 'react'
import { Avatar, Checkbox, Space } from 'antd'
import type { CheckboxValueType } from 'antd/es/checkbox/Group'
import { useGetFamilyKids } from 'api/family/family'
import Spinner from 'components/Spinner'
import { FamilyMemberKid } from 'types/family/kid'
import { getNameSignature } from 'helper/utility'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import NoDataFound from 'components/global/NoDataFound'
import { adultStore } from 'state/family/adult'
import { FamilyMemberAdult } from 'types/family/adult'

interface SelectChildProps {
  familyId: string
}
interface StoreStatesProps {
  selectedKids: string[]
  setSelectedKids: (state: string[]) => void
  adultData: FamilyMemberAdult
}

const SelectChild: React.FC<SelectChildProps> = ({ familyId }) => {
  const { selectedKids, setSelectedKids, adultData }: StoreStatesProps =
    adultStore(store => ({
      selectedKids: store.selctedKids,
      setSelectedKids: store.setSelectedKids,
      adultData: store.adultData,
    }))
  const {
    isLoading: kidsLoading,
    data: kidsData,
    isFetching: kidsFetching,
    refetch: refetchKids,
  } = useGetFamilyKids(familyId)
  const onChange = (checkedValues: CheckboxValueType[]) => {
    setSelectedKids(checkedValues as string[])
  }

  const handleSelectAll = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      const kids = kidsData.map((kid: FamilyMemberKid) => {
        return kid._id
      })
      setSelectedKids(kids)
    } else {
      setSelectedKids([])
    }
  }

  useEffect(() => {
    if (kidsData && adultData && adultData.kidsIds) {
      if (adultData.kidsIds.length === 0) return
      setSelectedKids(adultData.kidsIds)
    }
  }, [kidsData])

  useEffect(() => {
    if (familyId) {
      refetchKids()
    }
  }, [familyId])

  return (
    <div className="mt-20px">
      <p className="text-14 w-400">
        This adult is authorized to pick up the following child(ren).
      </p>

      {kidsLoading || kidsFetching ? (
        <div className="py-40px flex align-center justify-center">
          <Spinner />
        </div>
      ) : kidsData.length === 0 ? (
        <NoDataFound message="Kids are not added in this family" />
      ) : (
        <div className="mt-20px">
          <div>
            <Checkbox
              className="mt-15px kids-listing-auth-adults checkbox-style"
              onChange={handleSelectAll}
              checked={kidsData.length === selectedKids.length}
            >
              <h4 className="w-600 text-16 ml-10px">Select all</h4>
            </Checkbox>
          </div>
          <div className="mt-15px">
            <Checkbox.Group
              onChange={onChange}
              className="d-flex flex flex-column flex-wrap gap-16px"
              value={selectedKids}
            >
              {kidsData.map((kid: FamilyMemberKid) => {
                return (
                  <Checkbox
                    value={kid._id}
                    key={kid._id}
                    className="mt-15px kids-listing-auth-adults checkbox-style"
                  >
                    <Space size={12} className="ml-10px">
                      <Avatar size={40} src={kid.photo?.post}>
                        {getNameSignature(`${kid.firstName} ${kid.lastName}`)}
                      </Avatar>
                      <h4 className="w-500 text-16">{`${kid.firstName} ${kid.lastName}`}</h4>
                    </Space>
                  </Checkbox>
                )
              })}
            </Checkbox.Group>
          </div>
        </div>
      )}
    </div>
  )
}

export default SelectChild
