import { Row } from 'antd'
import GuardianInfo from './GuardianHistory'
import GuardianGeneral from './GuardianGeneral'
const GuardianBasicInfo = ({ parent, refetch }: any) => {
  return (
    <Row gutter={10} className="bg-grey container padding-top-10">
      <GuardianInfo data={parent} refetchData={refetch} />
      <GuardianGeneral data={parent} />
    </Row>
  )
}

export default GuardianBasicInfo
