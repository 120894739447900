import { useQuery } from '@tanstack/react-query'
import { getGuardianMedia } from '../../../../../api/family'
import Spinner from '../../../../../components/Spinner'
import { useEffect, useState } from 'react'
import { useGetGuardianMedia } from 'api/family/guardian'
import Media from 'components/global/Media'

const GuardianMedia = ({ id }: any) => {
  const [params, setParams] = useState({ page: 1, limit: 20 })
  const { data, isLoading, refetch, isFetching } = useGetGuardianMedia(
    id,
    params,
  )

  useEffect(() => {
    refetch()
  }, [id])
  return (
    <div className="bg-grey container padding-top-10 h-full">
      <div className="bg-white p-30px h-full">
        <h1>Media</h1>
        {isLoading || isFetching ? (
          <div
            className="flex justify-center aligin-center items-center "
            style={{ height: '80vh' }}
          >
            <Spinner />
          </div>
        ) : (
          <Media data={data} parms={params} setParms={setParams} />
        )}
      </div>
    </div>
  )
}
export default GuardianMedia
