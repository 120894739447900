import { useParams } from 'react-router-dom'
import AppLayout from '../../../../components/layout/layout'
import { Row, Tabs } from 'antd'
import KidProfileHeader from './Header'
import KidBasicInfo from './BasicInfo'
import KidEvents from './Events'
import KidDocuments from './Documents'
import KidActivity from './Activity'
import Notes from '../NotesComp/Notes'
import { useGetKidById } from 'api/family/kid'
import { useEffect } from 'react'
import KidMedia from './Media'
const { TabPane } = Tabs

const KidProfile = () => {
  let { id }: { id: string } = useParams()
  const { isLoading, data, refetch } = useGetKidById(id)

  useEffect(() => {
    refetch()
  }, [id])

  return (
    <AppLayout id={id}>
      <div className="container bg-grey">
        <KidProfileHeader data={data} refetch={refetch} />
        <Row>
          <Tabs
            defaultActiveKey="1"
            style={{ width: '100%', height: '100%' }}
            className="bg-white profile-tabs"
          >
            <TabPane tab="Activity" key="1">
              <KidActivity />
            </TabPane>
            <TabPane tab="Events" key="2">
              <KidEvents />
            </TabPane>
            <TabPane tab="Basic info" key="3">
              <KidBasicInfo data={data} />
            </TabPane>
            <TabPane tab="Media" key="4">
              <KidMedia />
            </TabPane>
            <TabPane tab="Documents" key="5">
              <KidDocuments />
            </TabPane>
            <TabPane tab="Notes" key="6">
              <Notes id={id} familyId={data?.familyId} />
            </TabPane>
          </Tabs>
        </Row>
      </div>
    </AppLayout>
  )
}
export default KidProfile
