import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { axiosDelete, axiosGet, axiosPatch, axiosPost } from 'Redux/axiosHelper'
import { FamilyFullResp } from 'types/family/family'
import {
  CreateGuardianPayload,
  FamilyMemberGuardian,
  FindGuardianResp,
  UpdateGuardianPayload,
} from 'types/family/guardian'

const findGuardianByEmail = async (params: { email: string }) => {
  const response = await axiosGet(`v1/guardians/search`, params)
  return response.data
}

export const useFindGuardianByEmail = (params: { email: string }) => {
  const findGuardian = useQuery<FindGuardianResp>(
    ['find-guardina-by-email', params],
    () => (params ? findGuardianByEmail(params) : null),
  )
  return findGuardian
}

const createGuardian = async ({
  familyId,
  payload,
}: {
  familyId: string
  payload: CreateGuardianPayload
}) => {
  const response = await axiosPost(
    payload,
    `v1/families/${familyId}/add-guardian`,
  )
  return response.data
}

export const useCreateGuardian = () => {
  const queryClient = useQueryClient()
  return useMutation(createGuardian, {
    onSuccess: (newGuardian: FamilyMemberGuardian) => {
      queryClient.setQueryData(
        ['getFamilyData'],
        (oldData: FamilyFullResp | undefined) => {
          return oldData.guardians?.length > 0
            ? {
                ...oldData,
                guardians: [...oldData.guardians, newGuardian],
              }
            : {
                ...oldData,
                guardians: [newGuardian],
              }
        },
      )
    },
  })
}

// update guardian by id
const updateGuardian = async ({
  guardianId,
  payload,
}: {
  guardianId: string
  payload: UpdateGuardianPayload
}) => {
  const response = await axiosPatch(payload, `v1/guardians/${guardianId}`)
  return response.data
}

export const useUpdateGuardian = () => {
  const queryClient = useQueryClient()
  return useMutation(updateGuardian, {
    onSuccess: (updatedGuardian: FamilyMemberGuardian) => {
      queryClient.setQueryData(
        ['getFamilyData'],
        (oldData: FamilyFullResp | undefined) => {
          if (!oldData) return
          if (oldData.guardians.length === 0)
            return { ...oldData, guardians: [updatedGuardian] }

          return {
            ...oldData,
            guardians: oldData.guardians.map(
              (guardian: FamilyMemberGuardian) =>
                guardian._id === updatedGuardian._id
                  ? updatedGuardian
                  : guardian,
            ),
          }
        },
      )
      queryClient.setQueryData(['guardian-by-id'], () => {
        return { ...updatedGuardian }
      })
    },
  })
}

// delete guardian by id
const deleteGuardian = async (guardianId: string) => {
  const response = await axiosDelete(`v1/guardians/${guardianId}`)
  return response.data
}

export const useDeleteGuardian = () => {
  const queryClient = useQueryClient()
  return useMutation(deleteGuardian, {
    onSuccess: (deletedGuardian: FamilyMemberGuardian) => {
      queryClient.setQueryData(
        ['getFamilyData'],
        (oldData: FamilyFullResp | undefined) => {
          if (oldData.guardians.length === 0) return []
          return {
            ...oldData,
            guardians: oldData.guardians.filter(
              (guardian: FamilyMemberGuardian) =>
                guardian._id !== deletedGuardian._id,
            ),
          }
        },
      )
    },
  })
}

// const get guardian by id
const getGuardianById = async (guardianId: string) => {
  const response = await axiosGet(`v1/guardians/${guardianId}`)
  return response.data
}

export const useGetGuardianById = (guardianId: string) => {
  const guardianById = useQuery<FamilyMemberGuardian>(
    ['guardian-by-id'],
    () => (guardianById ? getGuardianById(guardianId) : null),
    { enabled: false },
  )
  return guardianById
}

// get guardian media
const getGuardianMedia = async (
  guardianId: string,
  params: {
    page: number
    limit?: number
    mediaType?: 'image' | 'audio' | 'video'
  },
) => {
  const response = await axiosGet(`v1/guardians/${guardianId}/media`, params)
  return response.data
}

export const useGetGuardianMedia = (
  guradianId: string,
  params: {
    page: number
    limit?: number
    mediaType?: 'image' | 'audio' | 'video'
  },
) => {
  const guardianMedida = useQuery<any>(
    ['guradian-media'],
    () => (guradianId ? getGuardianMedia(guradianId, params) : null),
    { enabled: false },
  )
  return guardianMedida
}
