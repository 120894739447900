import React from 'react'
import {
  allowedImageTypes,
  allowedVideoTypes,
  allowedAudioTypes,
} from 'helper/constants'
import { MediaPaginatedResp, MediaParams } from 'types/media'
import { Photo, File } from 'types/globalTypes'
import { Empty, Pagination } from 'antd'
import usePagination from 'helper/usePagination'

interface MediaProps {
  data: MediaPaginatedResp
  parms: MediaParams
  setParms: React.Dispatch<React.SetStateAction<MediaParams>>
}

const Media: React.FC<MediaProps> = ({ data, parms, setParms }) => {
  const [paginationInfo, onTableChange, PaginationNoTabel] = usePagination({
    totalCount: data?.count || 0,
    currentPage: parms.page,
    setParams: setParms,
  })
  return (
    <div>
      {data.count > 0 ? (
        <div>
          <div className="flex gap-40px flex-wrap">
            {data.records.map((media: File | Photo) => {
              return allowedImageTypes.includes(media.ext) ? (
                <div key={media?._id}>
                  <img
                    src={(media as Photo).post}
                    width={300}
                    height={300}
                    alt={(media as Photo).thumb}
                  />
                </div>
              ) : allowedVideoTypes.includes(media.ext) ? (
                <div key={media?._id}>
                  <video width="300" height="300" controls>
                    <source src={(media as File).link} type="video/mp4" />
                  </video>
                </div>
              ) : allowedAudioTypes.includes(media.ext) ? (
                <div key={media?._id}>
                  <audio controls>
                    <source src={(media as File).link} type="audio/mpeg" />
                    Your browser does not support the audio element.
                  </audio>
                </div>
              ) : null
            })}
          </div>
          {(data.hasNextPage || data.hasPrevPage) && (
            <div className="mt-40px">
              <Pagination
                className="pb-20"
                style={{ width: '100%', display: 'flex' }}
                {...paginationInfo}
                onChange={PaginationNoTabel}
              />
            </div>
          )}
        </div>
      ) : (
        <div className="flex align-center justify-center h-50vh">
          <Empty />
        </div>
      )}
    </div>
  )
}

export default Media
