import React from 'react'
import { guardianStore } from 'state/family/guardian'
import Details from './Details'
import GuardiansStep from '../GuardianSteps'
import CreatePassword from './CreatePassword'
import { FormInstance } from 'antd'

interface MemberFoundDetailsProps {
  form: FormInstance
  familyId: string
}

const MemberFoundDetails = ({ form, familyId }) => {
  const { guardianData, guardianCounter } = guardianStore(store => ({
    guardianData: store.guardianData,
    guardianCounter: store.guardianCounter,
  }))
  return (
    <>
      <GuardiansStep />
      <div>
        {guardianCounter === 0 && <Details data={guardianData} />}
        {guardianCounter === 1 && (
          <CreatePassword form={form} familyId={familyId} />
        )}
      </div>
    </>
  )
}

export default MemberFoundDetails
