import { useParams } from 'react-router-dom'
import { Row, Tabs } from 'antd'
import AppLayout from '../../../../components/layout/layout'
import { useQueryClient } from '@tanstack/react-query'
import Spinner from '../../../../components/Spinner'
import GuardianBasicInfo from './BasicInformation'
import Header from './Header/Header'
import GuardianEvents from './GuardianEvents'
import Media from './Media'
import { useEffect } from 'react'
import GuardianDocuments from './Documents'
import { forceRefetch } from '../../../../helper/utility'
import Notes from '../NotesComp/Notes'
import { useGetGuardianById } from 'api/family/guardian'
import { guardianStore } from 'state/family/guardian'
import GuardianCreation from '../FamilyProfile/FamilyMembers/Modals/Guardian/GuardianCreation'
import GuardianMedia from './Media'

const { TabPane } = Tabs
const GuardianProfile = () => {
  const { id }: { id: string } = useParams()
  const { data, isLoading, refetch } = useGetGuardianById(id)
  const guardianCreationModal = guardianStore(
    store => store.guardianCreationModal,
  )
  const queryClient = useQueryClient()
  const update = () => {
    forceRefetch('getGuardian', queryClient)
    refetch()
  }
  useEffect(() => {
    refetch()
  }, [id])
  return (
    <AppLayout id={id}>
      <div className="container">
        {isLoading ? (
          <div className="h-full w-full flex align-center justify-center">
            <Spinner />
          </div>
        ) : (
          <>
            <Header data={data} refetch={update} />
            <Row>
              <Tabs
                defaultActiveKey="1"
                style={{ width: '100%', height: '100%' }}
                className="bg-white profile-tabs"
              >
                <TabPane tab="Basic Info" key="1">
                  <GuardianBasicInfo parent={data} refetch={refetch} />
                </TabPane>
                <TabPane tab="Events" key="2">
                  <GuardianEvents id={data.id} />
                </TabPane>
                <TabPane tab="Media" key="3">
                  <GuardianMedia id={data.id} />
                </TabPane>
                <TabPane tab="Documents" key="4">
                  <GuardianDocuments />
                </TabPane>
                <TabPane tab="Notes" key="5">
                  <Notes id={id} familyId={data?.familyId} />
                </TabPane>
              </Tabs>
            </Row>
          </>
        )}
      </div>
      {guardianCreationModal && <GuardianCreation familyId="" />}
    </AppLayout>
  )
}
export default GuardianProfile
