import React from 'react'
import { Avatar } from 'antd'
import IconAndContent from 'components/global/IconAndContent'
import { getNameSignature } from 'helper/utility'
import moment from 'moment'
import { FamilyMemberAdult } from 'types/family/adult'

interface MemeberDetailsProps {
  data: FamilyMemberAdult
}

const MemeberDetails: React.FC<MemeberDetailsProps> = ({ data }) => {
  return (
    <div>
      <div className="mt-20px">
        <p className="text-14 w-400">
          Would you like to add the following account as authorize adult to your
          family?
        </p>
      </div>
      {data && (
        <div className="shadow-box px-15px py-20px border-1px radius-12 mt-15px">
          <div className="flex align-center gap-16px">
            <Avatar size={80} src={data.photo?.post}>
              {getNameSignature(`${data.firstName} ${data.lastName}`)}
            </Avatar>
            <div>
              <h3 className="text-18 w-500">
                {data.firstName} {data.lastName}
              </h3>
              <p className="text-14 w-400 text-gray">
                {moment(data?.dob).format('DD MMM, YYYY')}
              </p>
            </div>
          </div>

          <div className="mt-30px">
            <IconAndContent
              nameIcon={'EnvelopeSimple'}
              heading="Email"
              text={data.email}
            />
          </div>
          {data.phone && (
            <div className="mt-15px">
              <IconAndContent
                nameIcon={'Phone'}
                heading="Phone number"
                text={data.phone?.number}
              />
            </div>
          )}
          {data.address && (
            <div className="mt-15px">
              <IconAndContent
                nameIcon={'MapPin'}
                heading="Address"
                text={`${data.address?.street}, ${data.address?.apartment}, ${data.address?.country}`}
              />
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default MemeberDetails
