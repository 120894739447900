import React, { useEffect, useState } from 'react'
import GlobalEventComponent from 'components/Events/Events'
import { GetEventParams } from 'types/events'
import { useGetAdultEvnetsById } from 'api/family/adult'

interface AdultEventsProps {
  adultId: string
}

const Events: React.FC<AdultEventsProps> = ({ adultId }) => {
  const [param, setParms] = useState<GetEventParams>({
    page: 1,
    limit: 10,
  })

  const { data, isLoading, isFetching, refetch } = useGetAdultEvnetsById(
    adultId,
    param,
  )

  useEffect(() => {
    refetch()
  }, [adultId])

  return (
    <div className="h-80vh">
      <GlobalEventComponent
        data={data}
        isLoading={isLoading || isFetching}
        parms={param}
        setParms={setParms}
      />
    </div>
  )
}

export default Events
