import React from 'react'
import CustomModal from 'components/global/CustomModal'
import MemberFoundDetails from './ModalContent/EmailFound/MemberFoundDetails'
import { guardianStore } from 'state/family/guardian'
import CustomModalFooter from 'components/global/CustomModalFooter'
import { failureMessage } from 'api/global'
import {
  CreateGuardianPayload,
  FamilyMemberGuardian,
} from 'types/family/guardian'
import useFormSubmit from 'helper/Hooks/formSubmit'

interface GuardianStoreStates {
  guardianExistsAlready: boolean
  setGuardianExistAlready: (state: boolean) => void
  guardianData: FamilyMemberGuardian
  setGuardianData: (state: CreateGuardianPayload) => void
  guardianCounter: number
  setGuardianCounter: (state: number) => void
}

const MemberFound = ({ familyId }: { familyId: string }) => {
  const { form, handleSubmit } = useFormSubmit()
  const {
    guardianExistsAlready,
    setGuardianExistAlready,
    guardianData,
    setGuardianData,
    guardianCounter,
    setGuardianCounter,
  }: GuardianStoreStates = guardianStore(store => ({
    guardianExistsAlready: store.guardianExistsAlready,
    setGuardianExistAlready: store.setGuardianExistAlready,
    guardianData: store.guardianData,
    setGuardianData: store.setGuardianData,
    guardianCounter: store.guardianCounter,
    setGuardianCounter: store.setGuardianCounter,
  }))

  const handleCancel = () => {
    setGuardianCounter(0)
    setGuardianData(null)
    setGuardianExistAlready(false)
  }
  const hanldeClose = () => {}
  const handleBack = () => {
    setGuardianCounter(0)
  }
  const hanldeNextClick = () => {
    if (!guardianData) return failureMessage('No data for guardian exists!')
    if (guardianCounter === 0) {
      const payload: CreateGuardianPayload = {
        photoId:
          guardianData && guardianData.photoId
            ? guardianData.photoId
            : undefined,
        firstName: guardianData ? guardianData.firstName : undefined,
        middleName:
          guardianData && guardianData.middleName
            ? guardianData.middleName
            : undefined,
        nickName:
          guardianData && guardianData.nickName
            ? guardianData.nickName
            : undefined,
        lastName: guardianData ? guardianData.lastName : undefined,
        gender:
          guardianData && guardianData.gender ? guardianData.gender : undefined,
        phone:
          guardianData && guardianData.phone ? guardianData.phone : undefined,
        address:
          guardianData && guardianData.address
            ? guardianData.address
            : undefined,
        relationId:
          guardianData && guardianData.relation
            ? guardianData.relation._id
            : undefined,
        allergies: guardianData ? guardianData.allergies : undefined,
        medicalNotes:
          guardianData && guardianData.medicalNotes
            ? guardianData.medicalNotes
            : undefined,
        email: guardianData ? guardianData.email : undefined,
        password: '',
      }
      setGuardianData(payload)
      setGuardianCounter(1)
      return
    }
  }
  return (
    <>
      <CustomModal
        openModal={guardianExistsAlready}
        isCloseModalCustom
        closeModal={handleCancel}
        title={guardianCounter === 0 ? 'Account found' : 'Add password'}
        footer={
          <CustomModalFooter
            multibutton={guardianCounter === 0 ? false : true}
            btnAlignment={
              guardianCounter === 0 ? 'justify-end' : 'justify-between'
            }
            btnType="primary"
            btnText={guardianCounter === 0 ? 'Next' : 'Back,Create guardian'}
            closeModal={guardianCounter === 0 ? hanldeClose : handleBack}
            onSubmit={guardianCounter === 0 ? hanldeNextClick : handleSubmit}
          />
        }
      >
        <MemberFoundDetails form={form} familyId={familyId} />
      </CustomModal>
    </>
  )
}

export default MemberFound
