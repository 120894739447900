import React, { useEffect, useState } from 'react'
import { Form, Row, Col, Input, FormInstance } from 'antd'
import { useFindAdultByEmail } from 'api/family/adult'
import { FindAdultParams } from 'types/family/adult'
import { myStore } from 'state/store'
import { failureMessage, infoMessage } from 'api/global'
import { adultStore } from 'state/family/adult'

interface FindEmailProps {
  form: FormInstance
  familyId: string
}

const FindEmail: React.FC<FindEmailProps> = ({ form, familyId }) => {
  const [params, setParams] = useState<FindAdultParams>()
  const setModalBtnLoader = myStore(store => store.setModalBtnLoader)
  const {
    setAdultModalVisible,
    setAdultExistModal,
    setAdultData,
    setEmailNotFoundModal,
    setFoundEmail,
  } = adultStore(store => ({
    setAdultModalVisible: store.setAdultModalVisible,
    setAdultExistModal: store.setAdultExistModal,
    setAdultData: store.setAdultData,
    setEmailNotFoundModal: store.setMailNotFoundModal,
    setFoundEmail: store.setFindEmail,
  }))

  const { data, isLoading } = useFindAdultByEmail(params)
  const formSchema = {}

  const onFinish = (values: { email: string }) => {
    setParams({
      email: values.email,
      familyId,
    })
  }

  useEffect(() => {
    if (isLoading) {
      setModalBtnLoader(true)
    } else {
      setModalBtnLoader(false)
    }
  }, [isLoading])

  useEffect(() => {
    if (!data) return
    if (data.type === 'self') {
      return failureMessage('Memeber already exist with same email')
    }
    if (data.type === 'member-found') {
      setAdultData(data.member)
      setAdultModalVisible(false)
      setAdultExistModal(true)
    }
    if (data.type === 'not-found') {
      setEmailNotFoundModal(true)
      setFoundEmail(params.email)
      setAdultModalVisible(false)
    }
  }, [data])

  return (
    <div>
      <p>
        Please enter the authorized adult's email. We will check our system to
        see if this account already exists.
      </p>
      <div className="mt-20px">
        <Form
          name="find-adult-email"
          initialValues={formSchema}
          form={form}
          onFinish={onFinish}
          layout="vertical"
          autoComplete="off"
        >
          <Row>
            <Col span={24}>
              <label htmlFor="" className="input-label required-input">
                Email
              </label>
              <Form.Item
                colon={false}
                name="email"
                className="form-control"
                rules={[
                  {
                    required: true,
                    message: 'Email is required',
                  },
                  { type: 'email', message: 'Email format is not right' },
                ]}
              >
                <Input
                  placeholder="example@gmail.com"
                  name="email"
                  className="form-control-field"
                  required={true}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  )
}

export default FindEmail
