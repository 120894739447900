import { Steps } from 'antd'
import { adultStore } from 'state/family/adult'
import { FamilyMemberAdult } from 'types/family/adult'
import AdultSteps from '../AdultSteps'
import MemeberDetails from './MemeberDetails'
import SelectChild from './SelectChild'
const { Step } = Steps

interface StoreStatesProps {
  adultData: FamilyMemberAdult
  adultCurrentStep: number
  setAdultCurrentStep: (state: number) => void
}

const AccountFound = ({ familyId }: { familyId: string }) => {
  const { adultData, adultCurrentStep, setAdultCurrentStep }: StoreStatesProps =
    adultStore(store => ({
      adultData: store.adultData,
      adultCurrentStep: store.adultCurrentStep,
      setAdultCurrentStep: store.setAdultCurrentStep,
    }))
  return (
    <>
      <AdultSteps />
      <div>
        {adultCurrentStep === 0 && <MemeberDetails data={adultData} />}
        {adultCurrentStep === 1 && <SelectChild familyId={familyId} />}
      </div>
    </>
  )
}

export default AccountFound
