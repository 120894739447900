import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import AppLayout from '../../../../components/layout/layout'
import { Row, Tabs } from 'antd'
import AdultProfileHeader from './Header'
import Notes from '../NotesComp/Notes'
import Spinner from 'components/Spinner'
import AdultsBasicInfo from '../../Profiles/AdultProfile/BasicInformation/index'
import { useGetAdultById } from 'api/family/adult'
import { adultStore } from 'state/family/adult'
import CreateAdult from '../FamilyProfile/FamilyMembers/Modals/AuthorizedAdult/CreateAdult'
import Events from './AdultEvents/Events'
import AdultMedia from './Media/AdultMedia'
import Documents from './Documents/Documents'
const { TabPane } = Tabs

const AdultProfile = () => {
  let { id }: { id: string } = useParams()
  const { adultCreationModal } = adultStore(store => ({
    adultCreationModal: store.adultCreationModal,
  }))
  const { isLoading, data, refetch, isFetching } = useGetAdultById(id)

  useEffect(() => {
    refetch()
  }, [])
  return (
    <AppLayout id={id}>
      <div className="container bg-grey">
        {isLoading || isFetching ? (
          <div className="h-100vh flex justify-center align-center">
            <Spinner />
          </div>
        ) : (
          <>
            <AdultProfileHeader data={data} refetch={refetch} />
            <Row>
              <Tabs
                defaultActiveKey="1"
                style={{ width: '100%', height: '100%' }}
                className="bg-white profile-tabs"
              >
                <TabPane tab="Basic Info" key="1">
                  <AdultsBasicInfo parent={data} refetch={refetch} />
                </TabPane>
                <TabPane tab="Events" key="2">
                  <Events adultId={id} />
                </TabPane>
                <TabPane tab="Media" key="3">
                  <AdultMedia />
                </TabPane>
                <TabPane tab="Documents" key="4">
                  <Documents />
                </TabPane>
                <TabPane tab="Notes" key="5">
                  <Notes id={id} familyId={data?.familyId} />
                </TabPane>
              </Tabs>
            </Row>
          </>
        )}
      </div>
      {adultCreationModal && <CreateAdult familyId={data.familyId} />}
    </AppLayout>
  )
}
export default AdultProfile
