import { useState } from 'react'
import { Col } from 'antd'
import CustomModal from 'components/global/CustomModal'
import { useParams } from 'react-router-dom'
import { adultStore } from 'state/family/adult'
import { guardianStore } from 'state/family/guardian'
import Spinner from '../../../../../components/Spinner'
import AlertModal from '../../../../../helper/AlertModal'
import FamilyCredit from '../Credits/FamilyCredits'
import FamilyData from './FamilyData'
import Addmember from './Modals/Addmember'
import AdultModal from './Modals/AuthorizedAdult'
import AlreadyExist from './Modals/AuthorizedAdult/AlreadyExist'
import CreateAdult from './Modals/AuthorizedAdult/CreateAdult'
import MailNotRegistered from './Modals/AuthorizedAdult/MailNotRegistered'
import GuardianModal from './Modals/Guardian'
import MemberFound from './Modals/Guardian/MemberFound'
import MailNotFound from './Modals/Guardian/MailNotFound'
import GuardianCreation from './Modals/Guardian/GuardianCreation'
import { kidStore } from 'state/family/kid'
import KidCreation from './Modals/Kid/KidCreation'

const FamilyProfile = ({ FamiliyData, isLoading, refetch }: any) => {
  const {
    adultModalVisible,
    adultExistModal,
    mailNotFoundModal,
    adultCreationModal,
  } = adultStore(store => ({
    adultModalVisible: store.adultModalVisible,
    adultExistModal: store.adultExistModal,
    mailNotFoundModal: store.mailNotFoundModal,
    adultCreationModal: store.adultCreationModal,
  }))

  const {
    guardianModalVisible,
    guardianExistModal,
    guardianMailNotFoundModal,
    guardianCreationModal,
  } = guardianStore(store => ({
    guardianModalVisible: store.guardianModalVisible,
    guardianExistModal: store.guardianExistsAlready,
    guardianMailNotFoundModal: store.mailNotfoundModal,
    guardianCreationModal: store.guardianCreationModal,
  }))

  const { kidCreationModal } = kidStore(store => ({
    kidCreationModal: store.kidCreationModal,
  }))

  const [alertModal, setAlertModal] = useState(false)
  const [alertModalData] = useState({
    name: 'adult',
    heading: 'Delete the adult',
    message1: `Are you sure you want to delete the 
    #adult_name Adult?`,
    closeIcon: false,
  })
  const [dataItem] = useState<any>()
  const [membersModalVisible, setMembersModalVisible] = useState(false)

  let { id }: any = useParams()

  return (
    <>
      <div className="bg-grey container padding-top-10 ">
        <div className="grid grid-50">
          <Col xs={{ span: 24 }} xl={{ span: 24 }}>
            {isLoading ? (
              <div className="flex justify-center h-full">
                <Spinner />
              </div>
            ) : (
              <FamilyData
                id={id}
                setMembersVisible={setMembersModalVisible}
                fdata={FamiliyData}
              />
            )}
          </Col>
          <FamilyCredit familyId={id} />
        </div>
      </div>
      {membersModalVisible && (
        <CustomModal
          openModal={membersModalVisible}
          closeModal={setMembersModalVisible}
          title=""
          footer={false}
        >
          <Addmember
            familyId={id}
            setMembersModalVisible={setMembersModalVisible}
          />
        </CustomModal>
      )}

      {alertModal && (
        <AlertModal
          isModalVisible={alertModal}
          setIsModalVisible={setAlertModal}
          data={alertModalData}
          dataItem={dataItem}
        />
      )}

      {/* adult modals */}
      {adultModalVisible && <AdultModal familyId={id} />}
      {adultExistModal && <AlreadyExist familyId={id} />}
      {mailNotFoundModal && <MailNotRegistered />}
      {adultCreationModal && <CreateAdult familyId={id} />}

      {/* guardian modal  */}
      {guardianModalVisible && <GuardianModal familyId={id} />}
      {guardianExistModal && <MemberFound familyId={id} />}
      {guardianMailNotFoundModal && <MailNotFound />}
      {guardianCreationModal && <GuardianCreation familyId={id} />}

      {/* kid modal  */}
      {kidCreationModal && <KidCreation familyId={id} />}
    </>
  )
}

export default FamilyProfile
