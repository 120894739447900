import { useQuery } from '@tanstack/react-query'
import { axiosGet } from 'Redux/axiosHelper'

const getFamilyKids = async (familyId: string) => {
  const response = await axiosGet(`v1/families/${familyId}/kids`)
  return response.data
}

export const useGetFamilyKids = (familyId: string) => {
  const familyKids = useQuery<any>(
    ['kids-for-family'],
    () => getFamilyKids(familyId),
    { enabled: false },
  )
  return familyKids
}
