import { Avatar, Col, DatePicker, Form, FormInstance, Input, Row } from 'antd'
import { useUpdateGuardian } from 'api/family/guardian'
import AllergyList from 'helper/AllergyList'
import FileUploader from 'helper/FileUploader'
import MapModal from 'helper/Map/MapModal'
import RelationsList from 'helper/RelationsList'
import { validateAlphabets, validatePhone } from 'helper/utility'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import PhoneInput from 'react-phone-input-2'
import { guardianStore } from 'state/family/guardian'
import { myStore } from 'state/store'
import {
  CreateGuardianPayload,
  FamilyMemberGuardian,
  UpdateGuardianPayload,
} from 'types/family/guardian'
import { Address, Phone } from 'types/globalTypes'

interface GuardianCreateFormProps {
  form: FormInstance
}
interface GuardianStoreStates {
  foundEmail: string
  guardianData: FamilyMemberGuardian
  setGuardianData: (data: CreateGuardianPayload) => void
  setGuardianCounter: (counter: number) => void
  isUpadtingGuardian: boolean
  setIsUpdatingGuardian: (state: boolean) => void
  setGuardianCreationModal: (state: boolean) => void
}

const GuardianCreateForm: React.FC<GuardianCreateFormProps> = ({ form }) => {
  const [avatar, setAvatar] = useState<string>('')
  const [phone, setPhone] = useState<Phone>({
    dialCode: '',
    isoCode: '',
    number: '',
  })
  const [mapModalVisible, setMapModalVisible] = useState(false)
  const [mapModalData, setMapModalData] = useState<Address>()
  const [allergies, setAllergies] = useState([])

  const {
    mutate: updateGuardianMutation,
    isLoading: updateGuardianMutationLoading,
  } = useUpdateGuardian()

  const {
    foundEmail,
    guardianData,
    setGuardianData,
    setGuardianCounter,
    isUpadtingGuardian,
    setIsUpdatingGuardian,
    setGuardianCreationModal,
  }: GuardianStoreStates = guardianStore(store => ({
    foundEmail: store.guardianEmail,
    guardianData: store.guardianData,
    setGuardianData: store.setGuardianData,
    setGuardianCounter: store.setGuardianCounter,
    isUpadtingGuardian: store.isUpdating,
    setIsUpdatingGuardian: store.setIsUpdating,
    setGuardianCreationModal: store.setGuardianCreationModal,
  }))
  const setModalBtnLoader = myStore(store => store.setModalBtnLoader)

  const formSchema = {
    photoId: guardianData ? guardianData?.photoId : undefined,
    firstName: guardianData ? guardianData?.firstName : undefined,
    middleName: guardianData ? guardianData?.middleName : undefined,
    nickName: guardianData ? guardianData?.nickName : undefined,
    lastName: guardianData ? guardianData.lastName : undefined,
    dob:
      guardianData && guardianData?.dob ? moment(guardianData.dob) : undefined,
    phone:
      guardianData && guardianData.phone?.number
        ? guardianData.phone.number
        : undefined,
    gender: guardianData ? guardianData?.gender : undefined,
    email: guardianData ? guardianData.email : foundEmail,
    allergies: guardianData ? guardianData.allergies : [],
    medicalNotes: guardianData ? guardianData?.medicalNotes : undefined,
    relationId: guardianData ? guardianData.relation?._id : undefined,
  }

  const onFinish = (values: CreateGuardianPayload) => {
    const payload = { ...values }
    payload.address = mapModalData ? mapModalData : undefined
    payload.phone = phone.number && phone.number.length > 4 ? phone : undefined
    payload.dob = values.dob
      ? moment(values.dob).format('YYYY-MM-DD')
      : undefined

    if (isUpadtingGuardian) {
      let deletedObject = {}
      for (const key in payload) {
        if (payload[key] === undefined) {
          deletedObject = { ...deletedObject, [key]: 1 }
        }
      }
      updateGuardianMutation(
        {
          guardianId: guardianData._id,
          payload: {
            ...payload,
            deleteObject: deletedObject,
          } as UpdateGuardianPayload,
        },
        {
          onSuccess: () => {
            setGuardianData(null)
            setIsUpdatingGuardian(false)
            setGuardianCreationModal(false)
          },
        },
      )
      return
    }

    setGuardianData(payload)
    setGuardianCounter(1)
  }

  useEffect(() => {
    setModalBtnLoader(updateGuardianMutationLoading)
  }, [updateGuardianMutationLoading])

  return (
    <div className="mt-20px">
      <Form
        name="guardian-creation-form"
        initialValues={formSchema}
        form={form}
        onFinish={onFinish}
        layout="vertical"
        autoComplete="off"
      >
        <Row gutter={24} align="middle">
          <Col span={4} className="mb-10px">
            <Avatar size={80} src={avatar ? avatar : ''}></Avatar>
          </Col>
          <Col span={18} className="">
            <Form.Item name="photoId">
              <FileUploader
                setAvatar={setAvatar}
                name="adult"
                form={form}
                fileId={
                  guardianData && guardianData?.photoId
                    ? guardianData.photoId
                    : undefined
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <p className="text-12 w-400">For security purposes, add a real photo</p>
        <Row gutter={24} className="mt-10px">
          <Col span={12}>
            <label className="input-label required-input">FirstName</label>
            <Form.Item
              colon={false}
              name="firstName"
              rules={[
                { required: true, message: 'First Name required' },
                { validator: validateAlphabets },
              ]}
            >
              <Input placeholder="Enter First Name" required name="firstname" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <label className="input-label">Middle Name</label>
            <Form.Item colon={false} name="middleName">
              <Input placeholder="Enter Middle Name" name="middleName" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={12}>
            <label className="input-label required-input">Last Name</label>
            <Form.Item
              colon={false}
              name="lastName"
              rules={[
                { required: true, message: 'Last Name required' },
                { validator: validateAlphabets },
              ]}
            >
              <Input placeholder="Enter Last Name" required name="lastName" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <label className="input-label">Preferred Name</label>
            <Form.Item colon={false} name="nickName">
              <Input placeholder="Enter Preferred Name" name="nickName" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={24}>
            <label className="input-label">Relationship</label>
            <Form.Item
              className="form-control"
              name="relationId"
              rules={[{ required: true, message: 'Relationship is required' }]}
            >
              <RelationsList
                form={form}
                relationId={formSchema.relationId}
                data={
                  guardianData && guardianData?.relation
                    ? guardianData.relation.name
                    : undefined
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <label className="input-label required-input">Date of Birth</label>
            <Form.Item name="dob" required>
              <DatePicker name="dob" className="selector w-full" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <label className="input-label ">Email</label>
            <Form.Item name="email">
              <Input placeholder="example@gmail.com" name="email" disabled />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <label className="input-label">Phone number</label>
            <Form.Item
              name="phone"
              className="phone-number-form"
              rules={[{ validator: validatePhone }]}
            >
              <PhoneInput
                country={phone?.isoCode || 'us'}
                onChange={(value: string, data: any) => {
                  setPhone({
                    dialCode: '+' + data?.dialCode,
                    number: '+' + value,
                    isoCode: data.countryCode.toUpperCase(),
                  })
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <label className="input-label required-input">Home address</label>
            <Form.Item>
              <Input
                placeholder="Location"
                onClick={() => {
                  setMapModalVisible(true)
                }}
                value={
                  mapModalData
                    ? mapModalData.city + ',' + mapModalData.state
                    : ''
                }
              />
              {mapModalVisible && (
                <MapModal
                  setMapModalVisible={setMapModalVisible}
                  setMapModalData={setMapModalData}
                  mapData={mapModalData}
                />
              )}{' '}
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <label className="input-label ">Allergies</label>
            <Form.Item
              name="allergies"
              className="form-control allergies-field "
            >
              <AllergyList
                form={form}
                setAllergies={setAllergies}
                data={
                  guardianData && guardianData.allergies
                    ? guardianData.allergies
                    : []
                }
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={24}>
            <label className="input-label">Medical Notes</label>
            <Form.Item name="medicalNotes">
              <Input
                placeholder="Ex.: Asthma, Lactose intolerant"
                name="medicalNotes"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default GuardianCreateForm
