import React, { useState, useEffect } from 'react'
import { Form, Col, Row, Input, Select, Avatar, Radio } from 'antd'
import {
  useGetActivityHighlightedElem,
  useGetActivityGoals,
  useGetActivityEnergyLevels,
  useGetActivityCategories,
  useCustomActivityStep1,
  useUpdateActivity,
} from 'api/EventCreation/CreationStep2'
import {
  ActivityListType,
  ActivityStep1Payload,
  defaultActivitiesResp,
} from 'types/events'
import { creationEventStore } from 'state/eventCreationStore'
import Loader from 'helper/Loader'
import ButtonSpinner from 'components/global/ButtonSpinner'
import UploadDocument from 'components/global/UploadDocument'
import { infoMessage } from 'api/global'
import { capitalizeFirstLetter, formValidated } from 'helper/utility'
import { Photo } from 'types/globalTypes'
const { Option } = Select

interface Step1Props {
  handleBack: () => void
  handleNext: () => void
  data?: defaultActivitiesResp
}

const Step1: React.FC<Step1Props> = ({ handleBack, handleNext, data }) => {
  const [form] = Form.useForm()
  const { organizationId } = creationEventStore(store => store.eventData)
  const setCustomActivity = creationEventStore(store => store.setCustomActivity)
  const { mutate: createActivityStep1, isLoading: createActivityStep1Loading } =
    useCustomActivityStep1()
  const { mutate: updateActivityStep1, isLoading: updateActivityLoading } =
    useUpdateActivity()
  const [loading, setLoading] = useState<boolean>(true)
  const [documentIds, setDocumentIds] = useState<string[]>([])
  const [photos, setPhotos] = useState<Photo[]>([])

  // const formSchema = {
  //   name: data ? data.name : null,
  //   categoryIds: data ? data.categoryIds : [],
  //   highlightedElementId: data ? data.highlightedElementId : null,
  //   goalId: data ? data?.goalId : null,
  //   energyLevelId: data ? data?.energyLevelId : null,
  //   environment: data ? data?.environment : null,
  //   time: data ? data?.time : null,
  //   plan: data ? data.plan : null,
  //   galleryIds: data ? data.galleryIds : [],
  // }

  const onFinish = (values: ActivityStep1Payload) => {
    if (!formValidated(values)) return

    const payload = {
      name: values.name,
      categoryIds: values.categoryIds,
      highlightedElementId: values.highlightedElementId,
      goalId: values.goalId,
      energyLevelId: values.energyLevelId,
      environment: values.environment,
      time: values.time,
      plan: values.plan,
      galleryIds: documentIds,
    }
    if (data) {
      updateActivityStep1(
        { payload, activityId: data._id },
        {
          onSuccess: data => {
            setCustomActivity(data)
            handleNext()
          },
        },
      )
    } else {
      if (organizationId) {
        createActivityStep1(
          { payload, orgId: organizationId },
          {
            onSuccess: data => {
              form.resetFields()
              setCustomActivity(data)
              handleNext()
            },
          },
        )
      }
    }
  }
  const {
    data: activityCategories,
    isLoading: activityLoading,
    isFetching: activityFetching,
    refetch: activityCategoryrefetch,
  } = useGetActivityCategories()
  const {
    data: activityEnergyLevels,
    isLoading: energylevelLoading,
    isFetching: energyLevelFetching,
    refetch: energyLevelRefetch,
  } = useGetActivityEnergyLevels()
  const {
    data: activityGoals,
    isLoading: activityGoalsLoading,
    isFetching: activityGoalsFetching,
    refetch: activityGoalsRefetch,
  } = useGetActivityGoals()
  const {
    data: activityHighlightElem,
    isLoading: activityHighlightElemLoading,
    isFetching: activityHighlightElemFetching,
    refetch: activityHighlightElemRefetch,
  } = useGetActivityHighlightedElem()

  useEffect(() => {
    activityCategoryrefetch()
    energyLevelRefetch()
    activityGoalsRefetch()
    activityHighlightElemRefetch()
  }, [])

  useEffect(() => {
    setLoading(
      activityLoading ||
        activityFetching ||
        energylevelLoading ||
        energyLevelFetching ||
        activityGoalsLoading ||
        activityGoalsFetching ||
        activityHighlightElemLoading ||
        activityHighlightElemFetching,
    )
  }, [
    activityLoading ||
      activityFetching ||
      energylevelLoading ||
      energyLevelFetching ||
      activityGoalsLoading ||
      activityGoalsFetching ||
      activityHighlightElemLoading ||
      activityHighlightElemFetching,
  ])

  useEffect(() => {
    if (data) {
      setDocumentIds(data ? data.galleryIds : [])
      setPhotos(data ? data.gallery : [])
    }
  }, [data])
  useEffect(() => {
    if (data) {
      form.setFieldsValue({ name: data.name })
      form.setFieldsValue({ categoryIds: data.categoryIds })
      form.setFieldsValue({ highlightedElementId: data.highlightedElementId })
      form.setFieldsValue({ goalId: data.goalId })
      form.setFieldsValue({ energyLevelId: data.energyLevelId })
      form.setFieldsValue({ environment: data.environment })
      form.setFieldsValue({ time: data.time })
      form.setFieldsValue({ plan: data.plan })
      form.setFieldsValue({ galleryIds: data.galleryIds })
    } else {
      form.resetFields()
    }
  }, [data])

  return (
    <div>
      {loading ? (
        <div className="py-30px ">
          <Loader />
        </div>
      ) : (
        <Form
          name="step1"
          // initialValues={formSchema}
          form={form}
          onFinish={onFinish}
          layout={'vertical'}
          autoComplete="off"
        >
          <Row>
            <Col span={24}>
              <label htmlFor="" className="input-label required-input">
                Activity name
              </label>
              <Form.Item colon={false} name="name" className="form-control">
                <Input
                  placeholder="Activity name"
                  name="name"
                  className="form-control-field"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col>
              <label className="input-label required-input">Category</label>
            </Col>
            <Col style={{ width: '100%' }}>
              <Form.Item className="form-control" name="categoryIds">
                <Select
                  mode={'multiple'}
                  className="form-control-field"
                  placeholder="Select"
                >
                  {activityCategories.length > 0 &&
                    activityCategories.map((item: ActivityListType) => (
                      <Option
                        value={item._id}
                        key={item._id}
                        name={item.name}
                        _id={item._id}
                      >
                        {
                          <Avatar size={18} src={item.icon} className="mr-5">
                            {item.name}
                          </Avatar>
                        }{' '}
                        {item.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col>
              <label className="input-label required-input">
                Highlighted element
              </label>
            </Col>
            <Col style={{ width: '100%' }}>
              <Form.Item className="form-control" name="highlightedElementId">
                <Select className="form-control-field" placeholder="Select">
                  {activityHighlightElem.length > 0 &&
                    activityHighlightElem.map((item: ActivityListType) => (
                      <Option
                        value={item._id}
                        key={item._id}
                        name={item.name}
                        _id={item._id}
                      >
                        {item.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col>
              <label className="input-label required-input">Goals</label>
            </Col>
            <Col style={{ width: '100%' }}>
              <Form.Item className="form-control" name="goalId">
                <Select className="form-control-field" placeholder="Select">
                  {activityGoals.length > 0 &&
                    activityGoals.map((item: ActivityListType) => (
                      <Option
                        value={item._id}
                        key={item._id}
                        name={item.name}
                        _id={item._id}
                      >
                        {item.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col>
              <label className="input-label required-input">
                Energy levels
              </label>
            </Col>
            <Col style={{ width: '100%' }}>
              <Form.Item className="form-control" name="energyLevelId">
                <Select className="form-control-field" placeholder="Select">
                  {activityEnergyLevels.length > 0 &&
                    activityEnergyLevels.map((item: ActivityListType) => (
                      <Option
                        value={item._id}
                        key={item._id}
                        name={item.name}
                        _id={item._id}
                      >
                        {item.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col>
              <label className="input-label required-input">
                Preferred Environment
              </label>
            </Col>
            <Col>
              <Form.Item className="form-control" name="environment">
                <Radio.Group>
                  <Radio value="indoors">Indoor</Radio>
                  <Radio value="outdoors">Outdoor</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col>
              <label className="input-label required-input">Time needed</label>
            </Col>
            <Col>
              <Form.Item className="form-control" name="time">
                <Radio.Group>
                  <Radio value="30 mins">30 mins</Radio>
                  <Radio value="45 mins">45 mins</Radio>
                  <Radio value="other">Other</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col>
              <label className="input-label required-input">
                Plan/Description
              </label>
            </Col>
            <Col>
              <Form.Item className="form-control" name="plan">
                <Input.TextArea
                  className="form-control-field"
                  placeholder="Short description of the activity"
                  style={{ height: '90px' }}
                ></Input.TextArea>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <label className="custom-field-label required-input">
                Add image
              </label>
            </Col>
            <Col span={16}>
              <UploadDocument
                documentIds={documentIds}
                setDocumentIds={setDocumentIds}
                multiple={true}
                docType="image/*"
                files={photos}
              />
            </Col>
          </Row>

          <div className="mt-10px flex align-center justify-between">
            <button
              className="create-activity-btn"
              type="button"
              onClick={handleBack}
            >
              Cancel
            </button>
            <button
              className="confirm-btn"
              type="submit"
              // onClick={handleSubmit}
            >
              {createActivityStep1Loading || updateActivityLoading ? (
                <ButtonSpinner color="#000" size={20} />
              ) : (
                'Next'
              )}
            </button>
          </div>
        </Form>
      )}
    </div>
  )
}

export default Step1
