import { Table } from 'antd'
import { useGetMemberAllDocuments } from 'api/Documents'
import Spinner from 'components/Spinner'
import StatusDropdown from 'helper/StatusDropdown'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { DocumentResp } from 'types/document'

interface DocumentsTableProps {
  memberId: string
}

const DocumentTable: React.FC<DocumentsTableProps> = ({ memberId }) => {
  const {
    data: documentsData,
    isLoading: documentsLoading,
    isFetching: documentsFetching,
    refetch: documentsRefetch,
  } = useGetMemberAllDocuments(memberId)
  const [tableData, setTableData] = useState([])

  //   table columns
  const columns = [
    {
      title: 'Document',
      dataIndex: 'document',
      key: 'document',
      width: '40%',
      sorter: (a: any, b: any) => a.members.length - b.members.length,
      render: (text: string) => <p className="text-14 w-500">{text}</p>,
    },
    {
      title: 'Updated Date',
      dataIndex: 'updated_date',
      key: 'updated_date',
      sorter: (a: any, b: any) => a.members.length - b.members.length,
      render: (text: string) => <p className="text-14 w-400">{text}</p>,
    },
    {
      title: 'Expiration Date',
      dataIndex: 'expiration_date',
      key: 'expiration_date',
      sorter: (a: any, b: any) => a.members.length - b.members.length,
      render: (text: string) => <p className="text-14 w-400">{text}</p>,
    },

    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '350px',
      render: (text: 'approved' | 'pending' | 'rejected', record: any) => (
        <StatusDropdown
          id={record.key}
          name="family-document"
          status={text}
          activeText="Approved"
          pendingText="Requested"
          rejectedText="Rejected"
          //   changeFn={changeStatus}
          //   refetchData={refetchFamilies}
        />
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      width: '100px',
      render: (text: string) => (
        <p className="text-15 w-500 prim-txt pointer">{text}</p>
      ),
    },
  ]

  useEffect(() => {
    if (documentsData) {
      const collectiveResponse = [
        ...documentsData.approvedDocs,
        ...documentsData.missingDocuments,
        ...documentsData.pendingDocs,
        ...documentsData.rejectedDocs,
      ]
      const enrichedData = collectiveResponse.map(
        (resp: DocumentResp, index: number) => {
          return {
            key: index,
            document: resp.type.name,
            updated_date: moment(resp.updatedAt).format('DD/MM/YYYY'),
            expiration_date: moment().format('DD/MM/YYYY'),
            status: resp.status,
            actions: 'View',
          }
        },
      )
      setTableData(enrichedData)
    }
  }, [documentsData])

  useEffect(() => {
    documentsRefetch()
  }, [memberId])
  return (
    <div className="mt-20px">
      {documentsLoading || documentsFetching ? (
        <div className="w-full h-70vh flex align-center justify-center">
          <Spinner />
        </div>
      ) : (
        <Table
          className="colored_header_table"
          columns={columns}
          dataSource={tableData}
          pagination={false}
        />
      )}
    </div>
  )
}

export default DocumentTable
