import React from 'react'
import { myStore } from 'state/store'
import ButtonSpinner from './ButtonSpinner'

interface CustomModalFooterProps {
  multibutton: boolean
  btnAlignment:
    | 'justify-center'
    | 'justify-between'
    | 'justify-start'
    | 'justify-end'
  btnText: string
  btnType?: 'primary' | 'secondary'
  closeModal?: React.Dispatch<React.SetStateAction<boolean>>
  onSubmit?: () => void
}

const CustomModalFooter: React.FC<CustomModalFooterProps> = ({
  multibutton,
  btnAlignment,
  btnText,
  btnType,
  closeModal,
  onSubmit,
}) => {
  const loading = myStore(store => store.modalBtnLoader)
  return (
    <div className="custom-modal-footer px-20px pb-20px">
      {multibutton ? (
        <div
          className={`flex ${btnAlignment} ${
            btnAlignment === 'justify-center' && 'gap-20px'
          }`}
        >
          <button
            className={`modal-footer-btn secondary`}
            onClick={() => closeModal(false)}
          >
            {btnText.split(',')[0]}
          </button>
          <button
            className={`modal-footer-btn primary`}
            type="submit"
            onClick={onSubmit}
            disabled={loading}
          >
            {loading ? (
              <ButtonSpinner size={20} color="#000" />
            ) : (
              btnText.split(',')[1]
            )}
          </button>
        </div>
      ) : (
        <div>
          <button
            className={`modal-footer-btn ${btnType}`}
            type="submit"
            onClick={onSubmit}
            disabled={loading}
          >
            {loading ? <ButtonSpinner size={20} color="#000" /> : btnText}
          </button>
        </div>
      )}
    </div>
  )
}

export default CustomModalFooter
