import React from 'react'
import emailNotFound from 'assets/Images/emailnotfoundicon.png'
import { guardianStore } from 'state/family/guardian'

const NotFound: React.FC = () => {
  const { email } = guardianStore(store => ({
    email: store.guardianEmail,
  }))
  return (
    <div className="flex align-center justify-center flex-col px-40px ">
      <img src={emailNotFound} alt="email-icon" />
      <h3 className="text-20 w-600 mt-30px">Email address is not registered</h3>
      <h4 className="text-14 w-500 mt-15px">{email}</h4>
      <p className="text-14 w-400 text-center">
        is not associated with any account. would you like to create new account
        with the provided email address?
      </p>
    </div>
  )
}

export default NotFound
