import React from 'react'
import { SearchOutlined } from '@ant-design/icons'
import './styles.scss'

const DocumentTableHeader = () => {
  return (
    <div>
      <div className="flex justify-between align-center gap-40px">
        <div className="search-input-box document-search document-search">
          <input
            type="search"
            className="search-input-group"
            placeholder="Search"
            onChange={e => {
              e.preventDefault()
              //   debounced(e.target.value)
            }}
          />
          <SearchOutlined />
        </div>
        <button className="borderd-btn">Request document</button>
      </div>
    </div>
  )
}

export default DocumentTableHeader
