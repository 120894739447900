import React, { useEffect, useState } from 'react'
import Spinner from 'components/Spinner'
import { useParams } from 'react-router-dom'
import { useGetAdultMediaById } from 'api/family/adult'
import { MediaParams } from 'types/media'
import Media from 'components/global/Media'

const AdultMedia: React.FC = () => {
  const { id }: { id: string } = useParams()
  const [parms, setParms] = useState<MediaParams>({
    page: 1,
    limit: 20,
  })
  const { isLoading, isFetching, data, refetch } = useGetAdultMediaById(
    id,
    parms,
  )

  useEffect(() => {
    refetch()
  }, [id])
  return (
    <div className="bg-grey container padding-top-10">
      <div className="profile_container">
        {isLoading || isFetching ? (
          <Spinner />
        ) : (
          <Media data={data} parms={parms} setParms={setParms} />
        )}
      </div>
    </div>
  )
}

export default AdultMedia
