import { FamilyMemberKid } from 'types/family/kid'
import { create } from 'zustand'

const kidCreationStore = (set: any) => ({
  // modals
  kidCreationModal: false,
  setKidCreationModal: (state: boolean) =>
    set(() => ({ kidCreationModal: state })),
  adultSelectModal: false,
  setAdultSelectModal: (state: boolean) =>
    set(() => ({ adultSelectModal: state })),
  //   datasets
  kidData: null,
  setKidData: (state: FamilyMemberKid) => set(() => ({ kidData: state })),
  databaseSelectedAdults: [],
  setDatabaseSelectedAdults: (state: string[]) =>
    set(() => ({ databaseSelectedAdults: state })),
  selectedAdults: [],
  setSelectedAdults: (state: string[]) =>
    set(() => ({ selectedAdults: state })),
  addedAdults: [],
  setAddedAdults: (state: string[]) => set(() => ({ addedAdults: state })),
  deletedAdults: [],
  setDeletedAdults: (state: string[]) => set(() => ({ deletedAdults: state })),
  // counter
  kidCounter: 0,
  setKidCounter: (state: number) => set(() => ({ kidCounter: state })),
})

export const kidStore = create(kidCreationStore)
