import React, { useState } from 'react'
import { Button } from 'antd'
import CustomModal from 'components/global/CustomModal'
import { adultStore } from 'state/family/adult'
import AdultModal from './AuthorizedAdult'
import { guardianStore } from 'state/family/guardian'
import { failureMessage } from 'api/global'
import { kidStore } from 'state/family/kid'

interface AddmemberProps {
  familyId: string
  setMembersModalVisible: React.Dispatch<React.SetStateAction<boolean>>
}

const Addmember: React.FC<AddmemberProps> = ({
  familyId,
  setMembersModalVisible,
}) => {
  const [guardiansModalVisible, setGuardiansModalVisible] = useState(false)
  const [adultsModalVisible, setAdultsModalVisible] = useState(false)
  const [kidModalVisible, setkidModalVisible] = useState(false)

  const setAdultModalVisible = adultStore(store => store.setAdultModalVisible)
  const setGuardianModalVisible = guardianStore(
    store => store.setGuardianModalVisible,
  )
  const setKidCreationModal = kidStore(store => store.setKidCreationModal)

  const handleButtonClick = (memberType: 'adult' | 'guardian' | 'kid') => {
    switch (memberType) {
      case 'adult':
        setAdultModalVisible(true)
        setMembersModalVisible(false)
        break
      case 'guardian':
        setGuardianModalVisible(true)
        setMembersModalVisible(false)
        break
      case 'kid':
        setKidCreationModal(true)
        setMembersModalVisible(false)
        break
      default:
        return failureMessage('No such operation for creating this type')
    }
  }

  return (
    <>
      <div className="flex flex-col justify-center align-center pb-40px">
        <h3 className="mb-25 mt-10">
          <b className="b1 Montserrat">
            Who would you like to add to the family?
          </b>
        </h3>
        <Button
          className="large_add_button w-325 w-500"
          onClick={() => handleButtonClick('guardian')}
        >
          Guardians
        </Button>
        <Button
          className="large_add_button w-325 mt-20px w-500"
          onClick={() => handleButtonClick('kid')}
        >
          Child
        </Button>

        <Button
          className="large_add_button w-325 mt-20px w-500"
          onClick={() => handleButtonClick('adult')}
        >
          Authorized Adults
        </Button>
      </div>
    </>
  )
}

export default Addmember
