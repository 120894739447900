import React from 'react'
import { guardianStore } from 'state/family/guardian'
import GuardiansStep from '../GuardianSteps'
import CreatePassword from '../EmailFound/CreatePassword'
import GuardianCreateForm from './GuardianCreateForm'
import { FormInstance } from 'antd'

interface CreateGuardianProps {
  form: FormInstance
  familyId: string
}

const CreateGuardian: React.FC<CreateGuardianProps> = ({ form, familyId }) => {
  const { guardianCounter, isUpdatingGuardian } = guardianStore(store => ({
    guardianCounter: store.guardianCounter,
    isUpdatingGuardian: store.isUpdating,
  }))
  return (
    <>
      {!isUpdatingGuardian && <GuardiansStep />}
      {guardianCounter === 0 && <GuardianCreateForm form={form} />}
      {guardianCounter === 1 && (
        <CreatePassword form={form} familyId={familyId} />
      )}
    </>
  )
}

export default CreateGuardian
