import { create } from 'zustand'

const guardianCreationStore = (set: any) => ({
  // modals
  guardianModalVisible: false,
  setGuardianModalVisible: (state: boolean) =>
    set(() => ({ guardianModalVisible: state })),
  guardianExistsAlready: false,
  setGuardianExistAlready: (state: boolean) =>
    set(() => ({ guardianExistsAlready: state })),
  mailNotfoundModal: false,
  setMailNotFoundModal: (state: boolean) =>
    set(() => ({ mailNotfoundModal: state })),
  guardianCreationModal: false,
  setGuardianCreationModal: (state: boolean) =>
    set(() => ({ guardianCreationModal: state })),
  //   datasets
  guardianData: null,
  setGuardianData: (state: any) => set(() => ({ guardianData: state })),
  guardianEmail: null,
  setGuardianEmail: (state: string) => set(() => ({ guardianEmail: state })),
  // counter && condtions
  guardianCounter: 0,
  setGuardianCounter: (state: number) =>
    set(() => ({ guardianCounter: state })),
  isUpdating: false,
  setIsUpdating: (state: boolean) => set(() => ({ isUpdating: state })),
})

export const guardianStore = create(guardianCreationStore)
