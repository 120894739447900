import React from 'react'
import AdultSteps from '../AdultSteps'
import AdultForm from './AdultForm'
import { FormInstance } from 'antd'
import { adultStore } from 'state/family/adult'
import SelectChild from '../AccountFound/SelectChild'

interface AdultCreateProps {
  form: FormInstance
  familyId: string
}

const AdultCreate: React.FC<AdultCreateProps> = ({ form, familyId }) => {
  const { adultCurrentStep } = adultStore(store => ({
    adultCurrentStep: store.adultCurrentStep,
  }))
  return (
    <>
      <AdultSteps />
      {adultCurrentStep === 0 && <AdultForm form={form} familyId={familyId} />}
      {adultCurrentStep === 1 && <SelectChild familyId={familyId} />}
    </>
  )
}

export default AdultCreate
