import {
  Avatar,
  Button,
  Checkbox,
  Col,
  Dropdown,
  Menu,
  Row,
  Space,
  Table,
} from 'antd'
import type { ColumnsType } from 'antd/lib/table'
import React, { useEffect, useState } from 'react'
import type { SorterResult } from 'antd/es/table/interface'
import { setPaginationObject } from '../../../../helper/utility'
import StatusDropdown from '../../../../helper/StatusDropdown'

const TableView = ({ events, setParms }: any) => {
  let initPaginationInfo = {
    total: events.count,
    current: 1,
    showTotal: (total: any, range: any) =>
      `Showing ${range[0]} to ${range[1]} of ${total} records`,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20'],
  }

  const [paginationInfo, setPaginationInfo] = useState(initPaginationInfo)
  useEffect(() => {
    setPaginationInfo({
      ...paginationInfo,
      total: events.count,
    })
    setEventData(eventData)
  }, [events.count])
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({})
  const [hiddenColumns, setHiddenColumns] = useState<any>({
    firstname: true,
    email: true,
    phone: true,
    dob: true,
    traininglevel: true,
    status: true,
    action: true,
    totalhours: true,
  })
  const [eventData, setEventData] = useState(events.records)
  const columns: ColumnsType<any> = [
    {
      title: 'Partner Name',
      width: 200,
      dataIndex: 'organization',
      key: 'partnerName',
      fixed: 'left',
      className: hiddenColumns.name === false ? 'display-none' : '',
      render: (text: any, record: any, index: any) => (
        <div className="cursor_pointer">
          <Space size={8}>
            <Avatar size={24} src={record?.organization?.photo?.thumb}>
              M
            </Avatar>
            {record?.organization?.name}
          </Space>
        </div>
      ),
    },
    {
      title: 'Event Name',
      width: 250,
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      // defaultSortOrder: 'descend',
      sorter: (a, b) => a.name.localeCompare(b.name),
      className: hiddenColumns.name === false ? 'display-none' : '',
    },
    {
      title: 'Score Percentage',
      dataIndex: 'address',
      key: '1',
      width: 150,
      sorter: (a: any, b: any) => a.phone.length - b.phone.length,
      sortOrder: sortedInfo.columnKey === 'phone' ? sortedInfo.order : null,
      className: hiddenColumns.phone === false ? 'display-none' : '',
    },
    {
      title: 'Total Results',
      dataIndex: 'address',
      key: '2',
      width: 150,
      sorter: (a: any, b: any) => a.address.length - b.address.length,
      sortOrder: sortedInfo.columnKey === 'address' ? sortedInfo.order : null,
      className: hiddenColumns.address === false ? 'display-none' : '',
    },
    {
      title: 'Score Percentage',
      dataIndex: 'address',
      key: '3',
      width: 150,
    },
    {
      title: 'Role',
      dataIndex: 'address',
      key: '4',
      width: 150,
    },
    {
      title: 'New Type of Event?',
      dataIndex: 'address',
      key: '5',
      width: 150,
    },
    {
      title: 'Size of Event',
      dataIndex: 'address',
      key: '6',
      width: 150,
    },
    {
      title: 'Date Inquired',
      dataIndex: 'address',
      key: '7',
      width: 150,
    },
    {
      title: 'Start of Event',
      dataIndex: 'address',
      key: '8',
    },
    {
      title: 'BR PR',
      dataIndex: 'address',
      key: '9',
    },
    {
      title: 'Urgency',
      width: 100,
      dataIndex: 'riskLevel',
      key: 'riskLevel',
      // sorter: (a: any, b: any) => a.name.length - b.name.length,
      // sortOrder: sortedInfo.columnKey === "name" ? sortedInfo.order : null,
      // className: hiddenColumns.name === false ? "display-none" : "",
    },
    {
      title: 'BR Staffing',
      dataIndex: 'address',
      key: '11',
    },
    {
      title: 'A.M. Descretion',
      dataIndex: 'address',
      key: '12',
    },
    {
      title: 'E.D. Descretion',
      dataIndex: 'address',
      key: '13',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: '14',
      sorter: (a: any, b: any) => a.status - b.status,
      render: (text: any, record: any, index: any) => (
        <div className="cursor_pointer">
          <StatusDropdown
            status={record?.status}
            name={'event'}
            changeFn={() => {}}
          />
        </div>
      ),
    },
    {
      title: 'Event Manager',
      dataIndex: 'organizers',
      key: '15',
      width: 150,
      render: (text: any, record: any, index: any) => (
        <div className="cursor_pointer">
          {/* <Dropdown
            menu={{
              items: [
                record?.organizers?.map((org: any) => {
                  return {
                    key: org._id,
                    label: (
                      <div>
                        <Avatar src={org?.photo?.thumb}>M</Avatar>
                        <h2>{org?.firstName + ' ' + org?.lastName}</h2>
                      </div>
                    ),
                  }
                }),
              ],
            }}
            placement="bottom"
          >
            <Button>
              {record?.organizers[0]
                ? record?.organizers[0]?.firstName +
                  record?.organizers[0]?.lastName
                : 'No Organizer found'}
            </Button>
          </Dropdown> */}
          <div className="px-8px py-5px border-1px  radius-6">
            <h2 className="text-12 flex">
              {record?.organizers
                ? record?.organizers[0]?.firstName
                : 'No Organizer found'}
            </h2>
          </div>
        </div>
      ),
    },
    {
      title: 'Request Approved',
      dataIndex: 'address',
      key: '16',
    },
    {
      title: 'Experience Design Started',
      dataIndex: 'address',
      key: '17',
    },
    {
      title: 'Experience Design Time',
      dataIndex: 'address',
      key: '18',
    },
    {
      title: 'Experience Design Deadline',
      dataIndex: 'address',
      key: '19',
    },
    {
      title: 'Done Kicked-Off',
      dataIndex: 'address',
      key: '20',
    },
    {
      title: 'Kick-Off Meeting',
      dataIndex: 'address',
      key: '21',
    },
    {
      title: 'Ready Supplies Packaged',
      dataIndex: 'address',
      key: '22',
    },
    {
      title: 'Supplies Time',
      dataIndex: 'address',
      key: '23',
    },
    {
      title: 'Supplies Deadline',
      dataIndex: 'address',
      key: '24',
    },
    {
      title: 'Staff Needed',
      dataIndex: 'address',
      key: '25',
    },
    {
      title: 'Staff Scheduled',
      dataIndex: 'address',
      key: '26',
    },
    {
      title: 'Staffing Deadline',
      dataIndex: 'address',
      key: '27',
    },
    {
      title: 'Event Launch Release',
      dataIndex: 'address',
      key: '28',
    },
    {
      title: 'Sign Ups Needed',
      dataIndex: 'minGuestsRequired',
      key: '29',
      render: (text: any, record: any, index: any) => (
        <span>{record.minGuestsRequired}</span>
      ),
    },
    {
      title: 'Sign Up #s',
      dataIndex: 'totalRegistrations',
      key: '30',
      render: (text: any, record: any, index: any) => (
        <span>{record.totalRegistrations}</span>
      ),
    },
    {
      title: 'Registration Cut-Off',
      dataIndex: 'address',
      key: '31',
    },
    {
      title: 'End of Event',
      dataIndex: 'address',
      key: '32',
    },
    {
      title: 'Wrapped-Up',
      dataIndex: 'address',
      key: '33',
    },
  ]
  const onChangeColumnCheckBox = (e: any) => {
    setHiddenColumns({
      ...hiddenColumns,
      [e.target.name]: e.target.checked == true,
    })
  }
  const menu2 = (
    <>
      <Menu
        // onClick={handleMenuClick}
        title="show all"
        items={[
          {
            label: (
              <Row gutter={10} justify="space-between">
                <Col span={4}>
                  <Checkbox
                    defaultChecked={!hiddenColumns.firstName}
                    name="name"
                    onChange={onChangeColumnCheckBox}
                  ></Checkbox>
                </Col>
                <Col span={20}>Partner Name </Col>
              </Row>
            ),
            key: '1',
          },
          {
            label: (
              <Row gutter={10} justify="space-between">
                <Col span={4}>
                  <Checkbox
                    defaultChecked={true}
                    name="age"
                    onChange={onChangeColumnCheckBox}
                  ></Checkbox>
                </Col>
                <Col span={20}>Event Name </Col>
              </Row>
            ),
            key: '2',
          },
          {
            label: (
              <Row gutter={10} justify="space-between">
                <Col span={4}>
                  <Checkbox
                    defaultChecked={true}
                    name="phone"
                    onChange={onChangeColumnCheckBox}
                  ></Checkbox>
                </Col>
                <Col span={20}>Points</Col>
              </Row>
            ),
            key: '3',
          },
          {
            label: (
              <Row gutter={10} justify="space-between">
                <Col span={4}>
                  <Checkbox
                    defaultChecked={true}
                    name="address"
                    onChange={onChangeColumnCheckBox}
                  ></Checkbox>
                </Col>
                <Col span={20}>Total Results </Col>
              </Row>
            ),
            key: '4',
          },
          {
            label: (
              <Row gutter={10} justify="space-between">
                <Col span={4}>
                  <Checkbox
                    defaultChecked={true}
                    name="totalhours"
                    onChange={onChangeColumnCheckBox}
                  ></Checkbox>
                </Col>
                <Col span={20}>Score Percentage </Col>
              </Row>
            ),
            key: '5',
          },
          {
            label: (
              <Row gutter={10} justify="space-between">
                <Col span={4}>
                  <Checkbox
                    defaultChecked={true}
                    name="traininglevel"
                    onChange={onChangeColumnCheckBox}
                  ></Checkbox>
                </Col>
                <Col span={20}>Role</Col>
              </Row>
            ),
            key: '6',
          },
          {
            label: (
              <Row gutter={10} justify="space-between">
                <Col span={4}>
                  <Checkbox
                    defaultChecked={true}
                    name="status"
                    onChange={onChangeColumnCheckBox}
                  ></Checkbox>
                </Col>
                <Col span={20}>New Type of Event? </Col>
              </Row>
            ),
            key: '7',
          },
          {
            label: (
              <Row gutter={10} justify="space-between">
                <Col span={4}>
                  <Checkbox
                    defaultChecked={true}
                    name="action"
                    onChange={onChangeColumnCheckBox}
                  ></Checkbox>
                </Col>
                <Col span={20}>Size of Event </Col>
              </Row>
            ),
            key: '8',
          },
          {
            label: (
              <Row gutter={10} justify="space-between">
                <Col span={4}>
                  <Checkbox
                    defaultChecked={true}
                    name="action"
                    onChange={onChangeColumnCheckBox}
                  ></Checkbox>
                </Col>
                <Col span={20}>Date Inquired</Col>
              </Row>
            ),
            key: '9',
          },
          {
            label: (
              <Row gutter={10} justify="space-between">
                <Col span={4}>
                  <Checkbox
                    defaultChecked={true}
                    name="action"
                    onChange={onChangeColumnCheckBox}
                  ></Checkbox>
                </Col>
                <Col span={20}>Start of Event</Col>
              </Row>
            ),
            key: '10',
          },
          {
            label: (
              <Row gutter={10} justify="space-between">
                <Col span={4}>
                  <Checkbox
                    defaultChecked={true}
                    name="action"
                    onChange={onChangeColumnCheckBox}
                  ></Checkbox>
                </Col>
                <Col span={20}>BR PR</Col>
              </Row>
            ),
            key: '11',
          },
          {
            label: (
              <Row gutter={10} justify="space-between">
                <Col span={4}>
                  <Checkbox
                    defaultChecked={true}
                    name="action"
                    onChange={onChangeColumnCheckBox}
                  ></Checkbox>
                </Col>
                <Col span={20}>Urgency</Col>
              </Row>
            ),
            key: '12',
          },
          {
            label: (
              <Row gutter={10} justify="space-between">
                <Col span={4}>
                  <Checkbox
                    defaultChecked={true}
                    name="action"
                    onChange={onChangeColumnCheckBox}
                  ></Checkbox>
                </Col>
                <Col span={20}>BR Staffing</Col>
              </Row>
            ),
            key: '13',
          },
          {
            label: (
              <Row gutter={10} justify="space-between">
                <Col span={4}>
                  <Checkbox
                    defaultChecked={true}
                    name="action"
                    onChange={onChangeColumnCheckBox}
                  ></Checkbox>
                </Col>
                <Col span={20}>A.M. Descretion</Col>
              </Row>
            ),
            key: '14',
          },
          {
            label: (
              <Row gutter={10} justify="space-between">
                <Col span={4}>
                  <Checkbox
                    defaultChecked={true}
                    name="action"
                    onChange={onChangeColumnCheckBox}
                  ></Checkbox>
                </Col>
                <Col span={20}>E.D. Descretion </Col>
              </Row>
            ),
            key: '15',
          },
          {
            label: (
              <Row gutter={10} justify="space-between">
                <Col span={4}>
                  <Checkbox
                    defaultChecked={true}
                    name="action"
                    onChange={onChangeColumnCheckBox}
                  ></Checkbox>
                </Col>
                <Col span={20}>Status</Col>
              </Row>
            ),
            key: '16',
          },
          {
            label: (
              <Row gutter={10} justify="space-between">
                <Col span={4}>
                  <Checkbox
                    defaultChecked={true}
                    name="action"
                    onChange={onChangeColumnCheckBox}
                  ></Checkbox>
                </Col>
                <Col span={20}>Event Manager </Col>
              </Row>
            ),
            key: '17',
          },
          {
            label: (
              <Row gutter={10} justify="space-between">
                <Col span={4}>
                  <Checkbox
                    defaultChecked={true}
                    name="action"
                    onChange={onChangeColumnCheckBox}
                  ></Checkbox>
                </Col>
                <Col span={20}>Request Approved </Col>
              </Row>
            ),
            key: '18',
          },
          {
            label: (
              <Row gutter={10} justify="space-between">
                <Col span={4}>
                  <Checkbox
                    defaultChecked={true}
                    name="action"
                    onChange={onChangeColumnCheckBox}
                  ></Checkbox>
                </Col>
                <Col span={20}>Experience Design Started </Col>
              </Row>
            ),
            key: '19',
          },
          {
            label: (
              <Row gutter={10} justify="space-between">
                <Col span={4}>
                  <Checkbox
                    defaultChecked={true}
                    name="action"
                    onChange={onChangeColumnCheckBox}
                  ></Checkbox>
                </Col>
                <Col span={20}>Experience Design Time </Col>
              </Row>
            ),
            key: '20',
          },
          {
            label: (
              <Row gutter={10} justify="space-between">
                <Col span={4}>
                  <Checkbox
                    defaultChecked={true}
                    name="action"
                    onChange={onChangeColumnCheckBox}
                  ></Checkbox>
                </Col>
                <Col span={20}>Experience Design Deadline </Col>
              </Row>
            ),
            key: '21',
          },
          {
            label: (
              <Row gutter={10} justify="space-between">
                <Col span={4}>
                  <Checkbox
                    defaultChecked={true}
                    name="action"
                    onChange={onChangeColumnCheckBox}
                  ></Checkbox>
                </Col>
                <Col span={20}>Done Kicked-Off </Col>
              </Row>
            ),
            key: '22',
          },
          {
            label: (
              <Row gutter={10} justify="space-between">
                <Col span={4}>
                  <Checkbox
                    defaultChecked={true}
                    name="action"
                    onChange={onChangeColumnCheckBox}
                  ></Checkbox>
                </Col>
                <Col span={20}>Kick-Off Meeting </Col>
              </Row>
            ),
            key: '23',
          },
          {
            label: (
              <Row gutter={10} justify="space-between">
                <Col span={4}>
                  <Checkbox
                    defaultChecked={true}
                    name="action"
                    onChange={onChangeColumnCheckBox}
                  ></Checkbox>
                </Col>
                <Col span={20}>Ready Supplies Packaged</Col>
              </Row>
            ),
            key: '24',
          },
          {
            label: (
              <Row gutter={10} justify="space-between">
                <Col span={4}>
                  <Checkbox
                    defaultChecked={true}
                    name="action"
                    onChange={onChangeColumnCheckBox}
                  ></Checkbox>
                </Col>
                <Col span={20}>Supplies Time</Col>
              </Row>
            ),
            key: '25',
          },
          {
            label: (
              <Row gutter={10} justify="space-between">
                <Col span={4}>
                  <Checkbox
                    defaultChecked={true}
                    name="action"
                    onChange={onChangeColumnCheckBox}
                  ></Checkbox>
                </Col>
                <Col span={20}>Supplies Deadline</Col>
              </Row>
            ),
            key: '26',
          },
          {
            label: (
              <Row gutter={10} justify="space-between">
                <Col span={4}>
                  <Checkbox
                    defaultChecked={true}
                    name="action"
                    onChange={onChangeColumnCheckBox}
                  ></Checkbox>
                </Col>
                <Col span={20}>Staff Needed </Col>
              </Row>
            ),
            key: '27',
          },
          {
            label: (
              <Row gutter={10} justify="space-between">
                <Col span={4}>
                  <Checkbox
                    defaultChecked={true}
                    name="action"
                    onChange={onChangeColumnCheckBox}
                  ></Checkbox>
                </Col>
                <Col span={20}>Staff Scheduled</Col>
              </Row>
            ),
            key: '28',
          },
          {
            label: (
              <Row gutter={10} justify="space-between">
                <Col span={4}>
                  <Checkbox
                    defaultChecked={true}
                    name="action"
                    onChange={onChangeColumnCheckBox}
                  ></Checkbox>
                </Col>
                <Col span={20}>Staffing Deadline</Col>
              </Row>
            ),
            key: '29',
          },
          {
            label: (
              <Row gutter={10} justify="space-between">
                <Col span={4}>
                  <Checkbox
                    defaultChecked={true}
                    name="action"
                    onChange={onChangeColumnCheckBox}
                  ></Checkbox>
                </Col>
                <Col span={20}>Event Launch Release </Col>
              </Row>
            ),
            key: '30',
          },
          {
            label: (
              <Row gutter={10} justify="space-between">
                <Col span={4}>
                  <Checkbox
                    defaultChecked={true}
                    name="action"
                    onChange={onChangeColumnCheckBox}
                  ></Checkbox>
                </Col>
                <Col span={20}>Sign Ups Needed </Col>
              </Row>
            ),
            key: '31',
          },
          {
            label: (
              <Row gutter={10} justify="space-between">
                <Col span={4}>
                  <Checkbox
                    defaultChecked={true}
                    name="action"
                    onChange={onChangeColumnCheckBox}
                  ></Checkbox>
                </Col>
                <Col span={20}>Sign Up #s </Col>
              </Row>
            ),
            key: '32',
          },
          {
            label: (
              <Row gutter={10} justify="space-between">
                <Col span={4}>
                  <Checkbox
                    defaultChecked={true}
                    name="action"
                    onChange={onChangeColumnCheckBox}
                  ></Checkbox>
                </Col>
                <Col span={20}>Registration Cut-Off </Col>
              </Row>
            ),
            key: '33',
          },
          {
            label: (
              <Row gutter={10} justify="space-between">
                <Col span={4}>
                  <Checkbox
                    defaultChecked={true}
                    name="action"
                    onChange={onChangeColumnCheckBox}
                  ></Checkbox>
                </Col>
                <Col span={20}>End of Event </Col>
              </Row>
            ),
            key: '34',
          },
          {
            label: (
              <Row gutter={10} justify="space-between">
                <Col span={4}>
                  <Checkbox
                    defaultChecked={true}
                    name="action"
                    onChange={onChangeColumnCheckBox}
                  ></Checkbox>
                </Col>
                <Col span={20}>Wrapped-Up </Col>
              </Row>
            ),
            key: '35',
          },
          {
            label: (
              <Row gutter={10} justify="space-between">
                <Col span={4}>
                  <Checkbox
                    defaultChecked={true}
                    name="action"
                    onChange={onChangeColumnCheckBox}
                  ></Checkbox>
                </Col>
                <Col span={20}>Wrap-Up Deadline</Col>
              </Row>
            ),
            key: '36',
          },
        ]}
      />
    </>
  )

  const onTableChange = (...rest: any) => {
    const newObject: any = [...rest]
    let newPaginationInfo = setPaginationObject.apply(paginationInfo, newObject)
    setPaginationInfo((prevParams: any) => ({
      ...prevParams,
      page: newPaginationInfo.current,
    }))

    getEvents(newPaginationInfo)
  }
  const getEvents = (newPaginationInfo: any) => {
    setParms((prev: any) => ({
      ...prev,
      page: newPaginationInfo.current,
      limit: 10,
    }))
  }
  return (
    <>
      <Table
        columns={columns}
        dataSource={eventData}
        scroll={{ x: 6000 }}
        pagination={paginationInfo}
        onChange={onTableChange}
        sticky
      />
    </>
  )
}

export default TableView
