import { Row, Col, Avatar, Space, Dropdown, Menu, Button } from 'antd'
import checkin from '../../../../../assets/Images/Calendar.svg'
import StatusDropdown from '../../../../../helper/StatusDropdown'
import pencilLine from '../../../../../assets/Images/PencilLine.svg'
import { useMutation } from '@tanstack/react-query'
import deleteSvg from '../../../../../assets/Images/delete_red.svg'
import { PlusOutlined } from '@ant-design/icons'
import { updateGuardianProfile } from '../../../../../api/family'
import { useState } from 'react'
import DeleteAlert from '../../../../../components/modals/deleteModal'
import { history } from '../../../../../Redux/app-store'
import { getNameSignature } from 'helper/utility'
import { guardianStore } from 'state/family/guardian'
const Header = ({ data, refetch }: any) => {
  const { setGuardianCreationModal, setGuardianData, setIsUpdating } =
    guardianStore(store => ({
      setGuardianCreationModal: store.setGuardianCreationModal,
      setGuardianData: store.setGuardianData,
      setIsUpdating: store.setIsUpdating,
    }))

  const handleEdit = () => {
    setGuardianData(data)
    setIsUpdating(true)
    setGuardianCreationModal(true)
  }
  const changeMutation = useMutation({
    mutationFn: async ({ params, payload }: any) => {
      return await updateGuardianProfile(params, payload)
    },
    onSuccess: () => {
      refetch()
    },
  })
  const deletMutation = useMutation({
    mutationFn: async ({ params, payload }: any) => {
      return await updateGuardianProfile(params, payload)
    },
  })
  const [deletefn, setShowDelete] = useState(false)
  const AlertData = {
    heading: 'Do you want to delete this Guardian',
    message:
      'This guardian will be deleted and if this is the only guardian whole family will be deleted ',
  }
  const menu = (
    <Menu>
      <Menu.Item key="checkin">
        <Space>
          <img alt="img" src={checkin}></img> Check-in to Event
        </Space>
      </Menu.Item>

      <Menu.Item key="Editprofile" onClick={handleEdit}>
        <Space>
          <img alt="img" src={pencilLine}></img> Edit profile
        </Space>
      </Menu.Item>
      <Menu.Item
        key="Delete"
        onClick={() => {
          setShowDelete(true)
        }}
      >
        <Space className="text-color-red">
          <img alt="img" src={deleteSvg}></img> Delete
        </Space>
      </Menu.Item>
    </Menu>
  )
  return (
    <div className="org-profile-header">
      <Row gutter={10}>
        <Col xl={{ span: 10 }}>
          <Row align="middle">
            <Col span={24} lg={{ span: 24 }}>
              <div className="d-flex align-center">
                <Avatar src={data?.photo?.post} size={80}>
                  {getNameSignature(data?.firstName + ' ' + data?.lastName)}
                </Avatar>
                <div className="avatar-name align-self-center">
                  <span className="profile_title">
                    {data?.firstName + ' ' + data?.lastName}
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col xl={{ span: 14 }}>
          <Row>
            <Col span={24} className="Organization-header">
              <Space>
                <h5 className="status-title">
                  <b className="b1">Status</b>
                </h5>
                <div className="profile-head-action-dropdown">
                  <StatusDropdown
                    id={data?.id}
                    name="guardian"
                    status={data?.status}
                    changeFn={changeMutation}
                    refetchData={refetch}
                  />
                </div>
              </Space>
              <Dropdown overlay={menu} className="status_dropdown">
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  className="actionButton actionButton-org-profile"
                  shape="round"
                >
                  Manage
                </Button>
              </Dropdown>
            </Col>
          </Row>
        </Col>
      </Row>
      {deletefn && (
        <DeleteAlert
          setIsModalVisible={setShowDelete}
          data={AlertData}
          onYesClick={() => {
            deletMutation.mutate({
              params: data.id,
              payload: { status: 'archived' },
            })
            history.push(`/family-profile/${data?.family?._id}`)
          }}
        />
      )}
    </div>
  )
}
export default Header
