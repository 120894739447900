import activeImg from 'assets/Images/activeGreen.png'
import pendingImg from 'assets/Images/pending.svg'
import blocked from 'assets/Images/blocked.svg'
import { Button, Dropdown, Space } from 'antd'
import { DownOutlined } from '@ant-design/icons'

const StatusDropdown = ({
  id,
  name,
  status,
  changeFn,
  refetchData,
  activeText,
  pendingText,
  rejectedText,
}: any) => {
  const handleChange = (key: string) => {
    if (name === 'guardian') {
      changeFn.mutate({ params: id, payload: { status: key } })
    } else {
      changeFn.mutate({ params: id, payload: key })
      refetchData()
    }
  }
  const handleMenuClick = ({ key }: any) => {
    handleChange(key)
  }
  const active = activeText
    ? activeText
    : name === 'organization'
    ? 'approved'
    : 'active'
  const pending = pendingText
    ? pendingText
    : name === 'organization'
    ? 'under_review'
    : name === 'mentor' || name === 'organizer'
    ? 'inactive'
    : 'pending'

  const StatusMenu = [
    {
      key: activeText ? activeText.toLowerCase() : active,
      label: (
        <div>
          <img alt="img" src={activeImg}></img>{' '}
          {`${
            activeText
              ? activeText.substring(0, activeText.length - 1)
              : 'Active'
          }`}
        </div>
      ),
    },
    {
      key: pendingText ? pendingText.toLowerCase() : pending,
      label: (
        <div>
          <img alt="img" src={pendingImg}></img>{' '}
          {`${
            pendingText
              ? pendingText.substring(0, pendingText.length - 2)
              : 'Pending'
          }`}
        </div>
      ),
    },
    {
      key: rejectedText ? rejectedText : 'blocked',
      label: (
        <div>
          <div>
            <img alt="img" src={blocked}></img>{' '}
            {`${
              rejectedText
                ? rejectedText.substring(0, rejectedText.length - 2)
                : 'Blocked'
            }`}
          </div>
          {/* {name === 'family' && (
            <div>
              <img alt="img" src={blocked}></img>Blocked
            </div>
          )} */}
        </div>
      ),
    },
  ]

  const imageSrc =
    status === 'active' || status === 'approved' || status === 'public'
      ? activeImg
      : status === 'blocked' ||
        status === 'private' ||
        status === 'archived' ||
        status === 'rejected'
      ? blocked
      : status === 'pending' ||
        status === 'inactive' ||
        status === 'under_review' ||
        status === 'draft'
      ? pendingImg
      : ''
  const text =
    status === 'pending' || status === 'under_review' || status === 'inactive'
      ? pendingText
        ? pendingText
        : 'Pending'
      : status === 'draft'
      ? 'Draft'
      : status === 'active' || status === 'approved'
      ? activeText
        ? activeText
        : 'Active'
      : status === 'public'
      ? 'Public'
      : status === 'blocked'
      ? 'Blocked'
      : status === 'private'
      ? 'Private'
      : status === 'archived'
      ? 'Archived'
      : status === 'rejected'
      ? 'Rejected'
      : ''
  return (
    <div>
      <Dropdown
        menu={{
          items: StatusMenu,
          onClick: handleMenuClick,
        }}
      >
        <Button className="status_button" style={{ minWidth: '180px' }}>
          <Space>
            <img alt="img" src={imageSrc}></img>
            {text}
            <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
    </div>
  )
}

export default StatusDropdown
