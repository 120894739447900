import CustomModal from 'components/global/CustomModal'
import CustomModalFooter from 'components/global/CustomModalFooter'
import useFormSubmit from 'helper/Hooks/formSubmit'
import React from 'react'
import { kidStore } from 'state/family/kid'
import KidSteps from './ModalContent/Steps'
import GeneralDetails from './ModalContent/GeneralDetails'
import Personalities from './ModalContent/Personalities'
import Abilities from './ModalContent/Abilities'
import { FamilyMemberKid } from 'types/family/kid'

interface KidCreation {
  familyId: string
}

interface KidStoreStates {
  kidCreationModal: boolean
  setKidCreationModal: (state: boolean) => void
  setKidData: (state: FamilyMemberKid) => void
  kidCounter: number
  setKidCounter: (state: number) => void
}

const KidCreation: React.FC<KidCreation> = ({ familyId }) => {
  const { form, handleSubmit } = useFormSubmit()
  const {
    kidCreationModal,
    setKidCreationModal,
    setKidData,
    kidCounter,
    setKidCounter,
  }: KidStoreStates = kidStore(store => ({
    kidCreationModal: store.kidCreationModal,
    setKidCreationModal: store.setKidCreationModal,
    setKidData: store.setKidData,
    kidCounter: store.kidCounter,
    setKidCounter: store.setKidCounter,
  }))

  const handleBack = () => {
    if (kidCounter === 0) {
      setKidCreationModal(false)
    } else {
      setKidCounter(kidCounter - 1)
    }
  }

  const hanldeCancel = () => {
    setKidCounter(0)
    setKidData(null)
    setKidCreationModal(false)
  }

  return (
    <>
      <CustomModal
        openModal={kidCreationModal}
        isCloseModalCustom
        closeModal={hanldeCancel}
        title="Create kid"
        footer={
          <CustomModalFooter
            multibutton
            btnAlignment="justify-between"
            btnText={
              kidCounter === 0
                ? 'Close,Next'
                : kidCounter === 1
                ? 'Back,Next'
                : 'Back, Create'
            }
            closeModal={handleBack}
            onSubmit={handleSubmit}
          />
        }
      >
        <>
          <KidSteps />
          {kidCounter === 0 && (
            <GeneralDetails form={form} familyId={familyId} />
          )}
          {kidCounter === 1 && <Personalities form={form} />}
          {kidCounter === 2 && <Abilities form={form} />}
        </>
      </CustomModal>
    </>
  )
}

export default KidCreation
