import React, { ReactNode } from 'react'
import { Modal } from 'antd'
import closeIcon from 'assets/Images/close.svg'

interface CustomModalProps {
  openModal: boolean
  isCloseModalCustom?: boolean
  closeModal: React.Dispatch<React.SetStateAction<boolean>> | (() => void)
  title: string
  width?: number
  footer: ReactNode
  children: ReactNode
}

const CustomModal: React.FC<CustomModalProps> = ({
  openModal,
  isCloseModalCustom,
  closeModal,
  title,
  width = 600,
  footer,
  children,
}) => {
  const handleCancel = () => {
    if (!isCloseModalCustom) {
      closeModal(false)
      return
    }
    ;(closeModal as () => void)()
  }

  return (
    <div>
      <Modal
        open={openModal}
        onCancel={handleCancel}
        footer={footer}
        width={width}
        centered
        maskClosable={false}
        className="custom-common-modal"
      >
        <div className="px-15px modal-body">
          <div className="flex-center pt-20px justify-between ">
            <h2 className="w-600 text-24">{title}</h2>
            <img
              src={closeIcon}
              alt="close-button"
              onClick={handleCancel}
              className="close button pointer"
            />
          </div>
          <div className="mt-30px modal-content">{children}</div>
        </div>
      </Modal>
    </div>
  )
}

export default CustomModal
