import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { getKidAuthorizedAdults } from '../../../../../api/family'
import Email from '../../../../../assets/Images/adultemail.svg'
import Phone from '../../../../../assets/Images/adultPhone.svg'
import admin from '../../../../../assets/Images/Amin.png'
import { getNameSignature } from 'helper/utility'
import { Avatar, Row } from 'antd'
import UserMisus from '../../../../../assets/Images/UserMinus.png'
import { useGetKidAutorizedAdults, useRemoveKidFromAdult } from 'api/family/kid'
import { useEffect, useState } from 'react'
import Spinner from 'components/Spinner'
import AlertModal from 'helper/AlertModal'
import { FamilyMemberAdult } from 'types/family/adult'
import { kidStore } from 'state/family/kid'
import SelectAdult from '../../FamilyProfile/FamilyMembers/Modals/Kid/SelectAdult'
const KidAuthorizedAdults = () => {
  const { id }: any = useParams()
  const [openAlertModal, setOpenAlertModal] = useState<boolean>(false)
  const [deletedAdultData, setDeletedAdultData] = useState<FamilyMemberAdult>()
  const {
    adultSelectModal,
    setAdultSelectModal,
    setDatabaseSelectedAdults,
    setSelectedAdults,
  } = kidStore(store => ({
    adultSelectModal: store.adultSelectModal,
    setAdultSelectModal: store.setAdultSelectModal,
    setSelectedAdults: store.setSelectedAdults,
    setDatabaseSelectedAdults: store.setDatabaseSelectedAdults,
  }))
  const { data, isLoading, refetch, isFetching } = useGetKidAutorizedAdults(id)
  const { mutate: removeAdultMutation, isLoading: removeAdultMutationLoading } =
    useRemoveKidFromAdult()

  const onYesClick = () => {
    removeAdultMutation(
      { payload: { kidId: id }, adultId: deletedAdultData._id },
      {
        onSuccess: () => {
          setOpenAlertModal(false)
        },
      },
    )
  }

  const hanldeRemoveAdult = (adultData: FamilyMemberAdult) => {
    setDeletedAdultData(adultData)
    setOpenAlertModal(true)
  }

  const handleAddAdult = () => {
    if (data) {
      const selected = data.adults.map((adult: FamilyMemberAdult) => adult._id)
      setDatabaseSelectedAdults(selected)
      setSelectedAdults(selected)
    }
    setAdultSelectModal(true)
  }

  useEffect(() => {
    refetch()
  }, [id])

  return (
    <div className=" h-full overflow-auto bg-white p-30px h-80vh ml-10px">
      {isFetching || isLoading ? (
        <div>
          <Spinner />
        </div>
      ) : (
        <>
          <div className="flex justify-between ">
            <h1>Authorized Adults</h1>
            <button className="borderd-btn" onClick={handleAddAdult}>
              Add adult
            </button>
          </div>
          {data?.adults?.map((data: any) => {
            return (
              <>
                <div className="flex justify-between align-center">
                  <div className="flex justify-start  mt-30px">
                    <Avatar src={data?.photo?.post} size={50}>
                      {getNameSignature(data?.firstName)}
                    </Avatar>
                    <div className="ml-20px">
                      {' '}
                      <h2 className="text-14">
                        {data?.firstName + ' ' + data?.lastName}
                      </h2>
                      <h3 className="grey-txt text-14">
                        {data?.relation?.name}
                      </h3>
                      {data?.email && (
                        <div className="flex">
                          <img src={Email} alt="email" />
                          <h3 className="text-14 ml-10px">{data?.email}</h3>
                        </div>
                      )}
                      <div className="flex">
                        <img src={Phone} alt="email" />
                        <h3 className="text-14 ml-10px">
                          {data?.phone?.number}
                        </h3>
                      </div>
                    </div>
                  </div>
                  <Row>
                    <img src={UserMisus} alt="" />
                    <h4
                      className="pointer"
                      style={{ color: '#FF6961', marginLeft: '10px' }}
                      onClick={() => hanldeRemoveAdult(data)}
                    >
                      Unauthorize
                    </h4>
                  </Row>
                </div>
              </>
            )
          })}

          {adultSelectModal && (
            <SelectAdult familyId={data.familyId} kidId={id} />
          )}
          {openAlertModal && (
            <AlertModal
              isModalVisible={openAlertModal}
              setIsModalVisible={setOpenAlertModal}
              data={{
                name: 'Delete Adult from kid',
                heading: 'Are you sure to remove adult from kid',
              }}
              onYesClick={onYesClick}
              loading={removeAdultMutationLoading}
            />
          )}
        </>
      )}
    </div>
  )
}
export default KidAuthorizedAdults
