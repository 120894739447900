import React from 'react'
import { Steps } from 'antd'
import { kidStore } from 'state/family/kid'
const { Step } = Steps

const KidSteps = () => {
  const kidCounter = kidStore(store => store.kidCounter)
  const steps = [
    {
      title: 'General details',
    },
    {
      title: 'Personality',
    },
    {
      title: 'Abilities',
    },
  ]
  return (
    <div className="border-btm pb-15px">
      <Steps current={kidCounter}>
        {steps.map(item => (
          <Step key={item.title} className="step-styling" title={item.title} />
        ))}
      </Steps>
    </div>
  )
}

export default KidSteps
