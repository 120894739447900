import React from 'react'
import CustomModal from 'components/global/CustomModal'
import CustomModalFooter from 'components/global/CustomModalFooter'
import { adultStore } from 'state/family/adult'
import MailNotFound from './ModalContent/MailNotFound'

const MailNotRegistered: React.FC = () => {
  const {
    mailNotFoundModal,
    setEmailNotFoundModal,
    setAdultModalVisble,
    setAdultCreationModal,
  } = adultStore(store => ({
    setAdultModalVisble: store.setAdultModalVisible,
    mailNotFoundModal: store.mailNotFoundModal,
    setEmailNotFoundModal: store.setMailNotFoundModal,
    setAdultCreationModal: store.setAdultCreationModal,
  }))

  const handleClose = () => {
    setAdultModalVisble(true)
    setEmailNotFoundModal(false)
  }
  const handleSubmit = () => {
    setAdultCreationModal(true)
    setEmailNotFoundModal(false)
  }
  return (
    <>
      <CustomModal
        openModal={mailNotFoundModal}
        closeModal={setEmailNotFoundModal}
        title={''}
        footer={
          <CustomModalFooter
            multibutton
            btnAlignment="justify-center"
            btnText={'Try another email,Create new profile'}
            closeModal={handleClose}
            onSubmit={handleSubmit}
          />
        }
      >
        <MailNotFound />
      </CustomModal>
    </>
  )
}

export default MailNotRegistered
