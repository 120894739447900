import { Avatar, Checkbox, Space } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { CheckboxValueType } from 'antd/lib/checkbox/Group'
import { useGetAdultsByFamilyId } from 'api/family/adult'
import NoDataFound from 'components/global/NoDataFound'
import Spinner from 'components/Spinner'
import { getNameSignature } from 'helper/utility'
import React, { useEffect } from 'react'
import { kidStore } from 'state/family/kid'
import { FamilyMemberAdult } from 'types/family/adult'

interface AdultSelectProps {
  familyId: string
}

const AdultSelect: React.FC<AdultSelectProps> = ({ familyId }) => {
  const {
    selctedAdults,
    setSelectedAdults,
    databaseSelectedAdults,
    addedAdults,
    setAddedAdults,
    deletedAdult,
    setDeletedAdults,
  } = kidStore(store => ({
    selctedAdults: store.selectedAdults,
    setSelectedAdults: store.setSelectedAdults,
    databaseSelectedAdults: store.databaseSelectedAdults,
    addedAdults: store.addedAdults,
    setAddedAdults: store.setAddedAdults,
    deletedAdult: store.deletedAdults,
    setDeletedAdults: store.setDeletedAdults,
  }))
  const {
    data: adultsData,
    isLoading: adultsLoading,
    isFetching: adultsFetching,
    refetch: adultsRefetch,
  } = useGetAdultsByFamilyId(familyId)

  const handleSelectAll = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      const adults = adultsData.map((adult: FamilyMemberAdult) => {
        return adult._id
      })
      setSelectedAdults(adults)
      const newAddedAdults = adults.filter(
        id => !databaseSelectedAdults.includes(id),
      )
      setAddedAdults(newAddedAdults)
      setDeletedAdults([])
    } else {
      const adults = adultsData.map((adult: FamilyMemberAdult) => {
        return adult._id
      })
      setSelectedAdults([])
      const newDeletedAdults = adults.filter(id =>
        databaseSelectedAdults.includes(id),
      )
      setDeletedAdults(newDeletedAdults)
      setAddedAdults([])
    }
  }

  const onChange = (checkedValues: CheckboxValueType[]) => {
    const selected = checkedValues as string[]

    const removedAdults = selctedAdults.filter(
      (id: string) => !selected.includes(id),
    )
    const newAddedAdults = selected.filter(id => !selctedAdults.includes(id))
    removedAdults.forEach(id => {
      if (databaseSelectedAdults.includes(id)) {
        setDeletedAdults([...deletedAdult, id])
      } else {
        setAddedAdults(addedAdults.filter(addedId => addedId !== id))
      }
    })

    newAddedAdults.forEach(id => {
      if (databaseSelectedAdults.includes(id)) {
        setDeletedAdults(deletedAdult.filter(deletedId => deletedId !== id))
      } else {
        setAddedAdults([...addedAdults, id])
      }
    })
    setSelectedAdults(selected)
  }

  useEffect(() => {
    if (familyId) {
      adultsRefetch()
    }
  }, [familyId])

  return (
    <div>
      <p className="text-14 w-400">
        Selected adults will be authorized to pick up the following child.
      </p>

      {adultsLoading || adultsFetching ? (
        <div className="py-40px flex align-center justify-center">
          <Spinner />
        </div>
      ) : adultsData.length === 0 ? (
        <NoDataFound message="Kids are not added in this family" />
      ) : (
        <div className="mt-20px">
          <div>
            <Checkbox
              className="mt-15px kids-listing-auth-adults checkbox-style"
              onChange={handleSelectAll}
              checked={adultsData.length === selctedAdults.length}
            >
              <h4 className="w-600 text-16 ml-10px">Select all</h4>
            </Checkbox>
          </div>
          <div className="mt-15px">
            <Checkbox.Group
              onChange={onChange}
              className="d-flex flex flex-column flex-wrap gap-16px"
              value={selctedAdults}
            >
              {adultsData.map((adult: FamilyMemberAdult) => {
                return (
                  <Checkbox
                    value={adult._id}
                    key={adult._id}
                    className="mt-15px kids-listing-auth-adults checkbox-style"
                  >
                    <Space size={12} className="ml-10px">
                      <Avatar size={40} src={adult.photo?.post}>
                        {getNameSignature(
                          `${adult.firstName} ${adult.lastName}`,
                        )}
                      </Avatar>
                      <h4 className="w-500 text-16">{`${adult.firstName} ${adult.lastName}`}</h4>
                    </Space>
                  </Checkbox>
                )
              })}
            </Checkbox.Group>
          </div>
        </div>
      )}
    </div>
  )
}

export default AdultSelect
