import { Col, Form, FormInstance, Input, Row } from 'antd'
import { useUpdateKid } from 'api/family/kid'
import moment from 'moment'
import React, { useEffect } from 'react'
import { kidStore } from 'state/family/kid'
import { myStore } from 'state/store'
import {
  CreateKidPayload,
  FamilyMemberKid,
  kidPersonalities,
  UpdateKidPayload,
} from 'types/family/kid'

interface PersonalitiesProps {
  form: FormInstance
}

interface KidStoreStates {
  kidData: FamilyMemberKid
  setKidData: (state: FamilyMemberKid) => void
  setKidCounter: (state: number) => void
}

const Personalities: React.FC<PersonalitiesProps> = ({ form }) => {
  const { mutate: kidUpdateMutation, isLoading: kidUpdateLoading } =
    useUpdateKid()
  const { kidData, setKidData, setKidCounter }: KidStoreStates = kidStore(
    store => ({
      kidData: store.kidData,
      setKidData: store.setKidData,
      setKidCounter: store.setKidCounter,
    }),
  )
  const setModalBtnLoader = myStore(store => store.setModalBtnLoader)

  const formSchema = {
    favoriteColor:
      kidData && kidData.personalities
        ? kidData.personalities.favoriteColor
        : undefined,
    personality:
      kidData && kidData.personalities
        ? kidData.personalities.personality
        : undefined,
    sensitiveSubject:
      kidData && kidData.personalities
        ? kidData.personalities.sensitiveSubject
        : undefined,
    leastFavoriteSubject:
      kidData && kidData.personalities
        ? kidData.personalities.leastFavoriteSubject
        : undefined,
  }

  const onFinish = (values: kidPersonalities) => {
    const payload: UpdateKidPayload = {
      ...kidData,
      personalities: { ...values },
    }
    payload.dob = kidData.dob
      ? moment(kidData.dob).format('YYYY-MM-DD')
      : undefined
    kidUpdateMutation(
      { kidId: kidData._id, payload },
      {
        onSuccess: data => {
          setKidData(data)
          setKidCounter(2)
        },
      },
    )
  }

  useEffect(() => {
    setModalBtnLoader(kidUpdateLoading)
  }, [kidUpdateLoading])

  return (
    <div className="mt-20px">
      <Form
        name="kid-creation-personalities"
        initialValues={formSchema}
        form={form}
        onFinish={onFinish}
        layout="vertical"
        autoComplete="off"
      >
        <Row>
          <Col span={24} className="mt-10px">
            <label className="input-label">Favorite Color</label>
            <Form.Item name="favoriteColor">
              <Input placeholder="Favorite Color" name="favoriteColor" />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24} className="mt-10px">
            <label className="input-label">Personality Trait</label>
            <Form.Item name="personality">
              <Input placeholder="Favorite Color" name="personality" />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24} className="mt-10px">
            <label className="input-label">Senitive Subjects</label>
            <Form.Item name="sensitiveSubject">
              <Input placeholder="Favorite Color" name="sensitiveSubject" />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24} className="mt-10px">
            <label className="input-label">Least Favorite School Subject</label>
            <Form.Item name="leastFavoriteSubject">
              <Input placeholder="Favorite Color" name="leastFavoriteSubject" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default Personalities
