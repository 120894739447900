import { useQuery } from '@tanstack/react-query'
import { axiosGet } from 'Redux/axiosHelper'
import { MemberDocumentResp } from 'types/document'

export const getDocumentById = async (documentId: string) => {
  const response = await axiosGet(`v1/files/${documentId}`)
  return response.data
}

// get member all documents
const getMemberAllDocument = async (memberId: string) => {
  const response = await axiosGet(`v1/documents/member-all-docs`, {
    belongsToId: memberId,
  })
  return response.data
}

export const useGetMemberAllDocuments = (memberId: string) => {
  const allDocuments = useQuery<MemberDocumentResp>(
    ['member-all-notes'],
    () => (memberId ? getMemberAllDocument(memberId) : null),
    { enabled: false },
  )
  return allDocuments
}
