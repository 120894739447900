import CustomModal from 'components/global/CustomModal'
import CustomModalFooter from 'components/global/CustomModalFooter'
import React from 'react'
import { guardianStore } from 'state/family/guardian'
import NotFound from './ModalContent/EmailNotFound/NotFound'

const MailNotFound: React.FC<any> = () => {
  const {
    mailNotFoundModal,
    setMailNotFoundModal,
    setGuardianModalVisible,
    setGuardianCreationModal,
  } = guardianStore(store => ({
    mailNotFoundModal: store.mailNotfoundModal,
    setMailNotFoundModal: store.setMailNotFoundModal,
    setGuardianModalVisible: store.setGuardianModalVisible,
    setGuardianCreationModal: store.setGuardianCreationModal,
  }))

  const handleClose = () => {
    setGuardianModalVisible(true)
    setMailNotFoundModal(false)
  }
  const handleSubmit = () => {
    setGuardianCreationModal(true)
    setMailNotFoundModal(false)
  }
  return (
    <>
      <CustomModal
        openModal={mailNotFoundModal}
        closeModal={setMailNotFoundModal}
        title=""
        footer={
          <CustomModalFooter
            multibutton
            btnAlignment="justify-center"
            btnText="Try another email,Create new profile"
            closeModal={handleClose}
            onSubmit={handleSubmit}
          />
        }
      >
        <NotFound />
      </CustomModal>
    </>
  )
}

export default MailNotFound
