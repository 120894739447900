import { FamilyMemberAdult } from 'types/family/adult'
import { create } from 'zustand'

const adultCreationStore = (set: any) => ({
  // modals
  adultModalVisible: false,
  setAdultModalVisible: (state: boolean) =>
    set(() => ({ adultModalVisible: state })),
  adultExistModal: false,
  setAdultExistModal: (state: boolean) =>
    set(() => ({ adultExistModal: state })),
  mailNotFoundModal: false,
  setMailNotFoundModal: (state: boolean) =>
    set(() => ({ mailNotFoundModal: state })),
  adultCreationModal: false,
  setAdultCreationModal: (state: boolean) =>
    set(() => ({ adultCreationModal: state })),

  // data sets
  adultCurrentStep: 0,
  setAdultCurrentStep: (state: number) =>
    set(() => ({ adultCurrentStep: state })),
  findEmail: null,
  setFindEmail: (state: string) => set(() => ({ findEmail: state })),
  adultData: null,
  setAdultData: (state: FamilyMemberAdult) => set(() => ({ adultData: state })),
  // selections
  selctedKids: [],
  setSelectedKids: (state: string[]) => set(() => ({ selctedKids: state })),
})

export const adultStore = create(adultCreationStore)
