import { FormInstance, Select } from 'antd'
import { useEffect } from 'react'

const GenderSelect = ({
  form,
  data,
}: {
  form: FormInstance
  data: 'male' | 'female' | undefined
}) => {
  const genders = [
    {
      key: 'male',
      name: 'Male',
    },
    {
      key: 'female',
      name: 'Female',
    },
  ]

  return (
    <div>
      <Select
        className="h-50px select-field"
        placeholder={'Select'}
        defaultValue={data}
        onChange={(v, item: any) => {
          form.setFieldsValue({
            gender: item.key,
          })
        }}
      >
        {genders.map((gender: { key: string; name: string }) => (
          <Select.Option key={gender.key} value={gender.name}>
            {gender.name}
          </Select.Option>
        ))}
      </Select>
    </div>
  )
}

export default GenderSelect
