import { DatePicker, Dropdown } from 'antd'
import moment from 'moment'
import { useEffect, useRef, useState } from 'react'
import Down from '../../../../../helper/Icons/DownIcon'
interface HeaderInterface {
  updateParams: React.Dispatch<React.SetStateAction<any>>
}
const EventAnalyticsHeader = ({ updateParams }: HeaderInterface) => {
  const [open, setOpen] = useState(0)
  const [selected, setSelected] = useState<number>(1)
  const [selectedDate, setSelectedDate] = useState<string>()
  const startDate = moment().startOf('week').format('DD MMM')
  const endDate = moment().endOf('week').format('DD MMM')
  const currentMonth = moment().format('MMM YY')
  const previousMonth = moment().clone().subtract(1, 'month').format('MMM YY')
  const [selectedWeeks, setSelectedWeeks] = useState<Date[]>([])
  const [selectedMonths, setSelectedMonths] = useState<Date[]>([])
  const handleWeekSelect = (week: Date) => {
    if (selectedWeeks.length === 0) {
      setSelectedWeeks([week])
    } else if (selectedWeeks.length === 1) {
      const start = selectedWeeks[0]
      const end = week
      setSelectedWeeks([start, end])
    } else if (selectedWeeks.length === 2) {
      setSelectedWeeks([week])
    }
  }
  const handleMonthSelect = (week: Date) => {
    if (selectedMonths.length === 0) {
      setSelectedMonths([week])
    } else if (selectedMonths.length === 1) {
      const start = selectedMonths[0]
      const end = week
      setSelectedMonths([start, end])
    } else if (selectedMonths.length === 2) {
      setSelectedMonths([week])
    }
  }
  const TimeStatus = [
    { key: 1, label: 'Week' },
    { key: 2, label: 'Month' },
  ]
  const DateSelector = [
    {
      key: '1',
      label: (
        <div>
          {selected === 1 ? (
            <div>
              <DatePicker
                picker="week"
                placeholder="Start Week"
                onChange={(dateString: any) => {
                  const weekStart = new Date(dateString)
                  weekStart.setDate(weekStart.getDate() - weekStart.getDay())
                  handleWeekSelect(weekStart)
                }}
              />
              <DatePicker
                picker="week"
                placeholder="End Week"
                className="ml-20px"
                onChange={(dateString: any) => {
                  const weekEnd = new Date(dateString)
                  weekEnd.setDate(weekEnd.getDate() - weekEnd.getDay())
                  handleWeekSelect(weekEnd)
                }}
              />
            </div>
          ) : (
            <div>
              <DatePicker
                picker="month"
                placeholder="Start Month"
                onChange={(dateString: any) => {
                  const MonthStart = new Date(dateString)
                  MonthStart.setDate(MonthStart.getDate() - MonthStart.getDay())
                  handleMonthSelect(MonthStart)
                }}
              />
              <DatePicker
                picker="month"
                placeholder="End Month"
                className="ml-20px"
                onChange={(dateString: any) => {
                  const MonthEnd = new Date(dateString)
                  MonthEnd.setDate(MonthEnd.getDate() - MonthEnd.getDay())
                  handleMonthSelect(MonthEnd)
                }}
              />
            </div>
          )}
        </div>
      ),
    },
  ]
  const dropdownRef = useRef<HTMLDivElement | null>(null)
  const onClick = (item: any) => {
    setSelected(parseInt(item.key))
  }

  const handleDocumentClick = (e: any) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setOpen(0)
    }
  }
  const handleDropdownClick = (e: React.MouseEvent) => {
    e.stopPropagation()
  }
  useEffect(() => {
    document.addEventListener('click', handleDocumentClick)
    return () => {
      document.removeEventListener('click', handleDocumentClick)
    }
  }, [])
  const formatDate = (date: Date) => {
    return moment(date).format('DD MMM')
  }
  const formatMonth = (date: Date) => {
    return moment(date).format('MMM YY')
  }
  const filterText = selected === 1 ? 'Week' : 'Month'
  const DateText =
    selected === 1
      ? selectedWeeks.length > 1
        ? formatDate(selectedWeeks[0]) + ' - ' + formatDate(selectedWeeks[1])
        : startDate + ' - ' + endDate
      : selected === 2
      ? selectedMonths.length > 1
        ? formatMonth(selectedMonths[0]) +
          ' - ' +
          formatMonth(selectedMonths[1])
        : previousMonth + ' - ' + currentMonth
      : 'Please Select'

  return (
    <div className="flex justify-between">
      <h1>Overview</h1>

      <div>
        <div ref={dropdownRef} onClick={handleDropdownClick}>
          <Dropdown
            menu={{
              items: TimeStatus,
              onClick,
            }}
            placement="bottomLeft"
            arrow={false}
            className="ml-20px mr-10px"
            open={open === 1}
          >
            <button
              className={` ${
                open === 1 ? 'filter-btn-click ' : 'filter-btn'
              } w-500`}
              onClick={() => setOpen(prev => (prev === 1 ? 0 : 1))}
            >
              {filterText}
              <Down />
            </button>
          </Dropdown>
          <Dropdown
            menu={{
              items: DateSelector,
            }}
            placement="bottomLeft"
            arrow={false}
            className="ml-20px mr-10px"
            open={open === 2}
          >
            <button
              className={` ${
                open === 2 ? 'filter-btn-click ' : 'filter-btn'
              } w-500`}
              onClick={() => setOpen(prev => (prev === 2 ? 0 : 2))}
            >
              {DateText}
              <Down />
            </button>
          </Dropdown>
        </div>
      </div>
    </div>
  )
}
export default EventAnalyticsHeader
