import React from 'react'
import CustomModal from 'components/global/CustomModal'
import CustomModalFooter from 'components/global/CustomModalFooter'
import { adultStore } from 'state/family/adult'
import FindEmail from './FindEmail'
import useFormSubmit from 'helper/Hooks/formSubmit'

const AdultModal = ({ familyId }: { familyId: string }) => {
  const { form, handleSubmit } = useFormSubmit()
  const adultModalVisible = adultStore(store => store.adultModalVisible)
  const setAdultModalVisible = adultStore(store => store.setAdultModalVisible)

  const handleClose = () => {
    setAdultModalVisible(false)
  }

  return (
    <>
      <CustomModal
        openModal={adultModalVisible}
        closeModal={setAdultModalVisible}
        title="Add Authorized Adults"
        footer={
          <CustomModalFooter
            multibutton={false}
            btnAlignment="justify-end"
            btnText={'Find account'}
            btnType={'primary'}
            closeModal={handleClose}
            onSubmit={handleSubmit}
          />
        }
      >
        <FindEmail form={form} familyId={familyId} />
      </CustomModal>
    </>
  )
}

export default AdultModal
