import { Col, Form, FormInstance, Input, Row } from 'antd'
import { useFindGuardianByEmail } from 'api/family/guardian'
import { failureMessage } from 'api/global'
import React, { useEffect, useState } from 'react'
import { guardianStore } from 'state/family/guardian'
import { myStore } from 'state/store'
import { FindGuardianResp } from 'types/family/guardian'

interface FindEmailforGuardianProps {
  form: FormInstance
}

const FindEmailForGuardian: React.FC<FindEmailforGuardianProps> = ({
  form,
}) => {
  const [params, setParams] = useState<any>()
  const formSchema = {}
  const setModalBtnLoader = myStore(store => store.setModalBtnLoader)
  const {
    setGuardianExistAlready,
    setGuardianModalVisible,
    setGuardianData,
    setMailNotFoundModal,
    setGuardianEmail,
  } = guardianStore(store => ({
    setGuardianExistAlready: store.setGuardianExistAlready,
    setGuardianModalVisible: store.setGuardianModalVisible,
    setGuardianData: store.setGuardianData,
    setMailNotFoundModal: store.setMailNotFoundModal,
    setGuardianEmail: store.setGuardianEmail,
  }))
  const { data, isLoading } = useFindGuardianByEmail(params)

  const onFinish = (values: { email: string }) => {
    setParams({
      email: values.email,
    })
  }

  useEffect(() => {
    if (isLoading) {
      setModalBtnLoader(true)
    } else {
      setModalBtnLoader(false)
    }
  }, [isLoading])

  useEffect(() => {
    if (!data) return
    if (data.type === 'self') {
      return failureMessage(data.message)
    }
    if (data.type === 'member-found') {
      setGuardianData(data.member)
      setGuardianExistAlready(true)
      setGuardianModalVisible(false)
      return
    }
    if (data.type === 'not-found') {
      setMailNotFoundModal(true)
      setGuardianEmail(params.email)
      setGuardianModalVisible(false)
    }
  }, [data])

  return (
    <div>
      <p>
        Please enter the guardian's email. We will check our system to see if
        this account already exists.
      </p>
      <div className="mt-20px">
        <Form
          name="find-adult-email"
          initialValues={formSchema}
          form={form}
          onFinish={onFinish}
          layout="vertical"
          autoComplete="off"
        >
          <Row>
            <Col span={24}>
              <label htmlFor="" className="input-label required-input">
                Email
              </label>
              <Form.Item
                colon={false}
                name="email"
                className="form-control"
                rules={[
                  {
                    required: true,
                    message: 'Email is required',
                  },
                  { type: 'email', message: 'Email format is not right' },
                ]}
              >
                <Input
                  placeholder="example@gmail.com"
                  name="email"
                  className="form-control-field"
                  required={true}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  )
}

export default FindEmailForGuardian
